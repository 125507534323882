import { Component, Input, OnInit, Output } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})

export class CompleteComponent implements OnInit {
  @LocalStorage()
  personalInfoData

  // resolutionUrl

  @Input()
  public href;

  public personFullName = "User"
  
  constructor(){
    
  }

  ngOnInit(){
    const { firstName, lastName } = this.personalInfoData.personal;
    this.personFullName = `${firstName.value} ${lastName.value}`; 
  }

}
