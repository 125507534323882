<div class="login-main register-form custom-p-20 p-relative">
  <div class="form-group mt-3 p-absolute btn-top-action text-end">
    <button class="btn btn-light btn-lg mx-3" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="previous()">Back</button>
    <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="makePayment()">Pay and Continue</button>
  </div>
    <ng-container *ngIf="loadCheckout()">
      <div class="card-header">
        <h4 class="font-primary m-b-20">Review Address and Complete Order</h4>
      </div>
      <div class="checkout">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="checkout-details p-2">
                  <div class="order-box">
                    <div class="checkbox-title">
                      <h4>Billing Address</h4>
                    </div>
                    <ul class="qty mt-4">
                     <li>
                        {{ billingInformation.firstName.value }} {{ billingInformation.lastName.value }} <br />
                        {{ billingInformation.address1.value }} {{ billingInformation.address2.value }} <br/>
                        {{ billingInformation.city.value }} {{ billingInformation.state.value }} <br/>
                        {{ billingInformation.zipCode.value }}
                      </li>
                    </ul>
                      <div class="checkbox-title">
                        <h4>Shipping Address</h4>
                      </div>
                          <ul class="qty mt-4">
                          <li>
                            {{ shippingInformation.firstName.value }} {{ shippingInformation.lastName.value }} <br />
                            {{ shippingInformation.address1.value }} {{ shippingInformation.address2.value }} <br/>
                            {{ shippingInformation.city.value }} {{ shippingInformation.state.value }} <br/>
                            {{ shippingInformation.zipCode.value }}
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="checkout-details p-2">
                    <div class="order-box">
                      <div class="title-box">
                        <div class="checkbox-title">
                          <h4>Product</h4>
                          <span>Total</span>
                        </div>
                      </div>
                      <ul class="qty">
                        <!-- <li *ngIf="deviceService.dataPlanDevice.planItem.length > 0">{{ deviceService.dataPlanDevice.planItem[0].name }} &times; 1 
                          <ng-container *ngIf="totalPaymentAmount !== 0; else stikedValuePlan">
                            <span>${{ deviceService.dataPlanDevice.planItem[0].price }}</span>
                          </ng-container>
                        <ng-template #stikedValuePlan>
                            <span><del>${{ deviceService.dataPlanDevice.planItem[0].price }} </del></span>
                        </ng-template>
                        </li> -->
                        <ng-container *ngFor="let device of deviceService.dataPlanDevice.allDevices">
                          <li *ngIf="deviceService.checkSelectedDevices(device.comboId)">{{device.comboName}} &times; 1
                            <ng-container *ngIf="totalPaymentAmount !== 0; else stikedValue ">
                              <span>${{device.comboPrice}}</span>
                            </ng-container>
                          <ng-template #stikedValue>
                            <span><del>${{device.comboPrice}}</del></span>
                          </ng-template>
                          </li>
                        </ng-container>
                      </ul>
                      <ul class="sub-total total">
                        <li>Total <span class="count">${{totalPaymentAmount}}</span></li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        <div class="m-t-20" *ngIf="totalPaymentAmount !== 0">
            <h5>Credit card</h5>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="form-group mb-3">
                    <label class="col-form-label f-16">Card Name*</label>
                    <input 
                        type="text"
                        name="ccName"
                        [(ngModel)]="ccDetails.ccName.value"
                        [ngClass]="{'is-invalid': ccDetails.ccName.isInValid}"
                        class="form-control"
                        placeholder="Eg. John Smith"
                        (blur)="checkCCValidation($event)"
                        aria-label="Credit Card Name"
                        aria-describedby="basic-addon1">
                        <div class="invalid-feedback">
                            Name is Required.
                        </div>
                </div>
              </div>
            <div class="col-12 col-sm-12">
            <div class="form-group mb-0">
                <label class="col-form-label f-16">Card Number*</label>
                <input 
                    type="text"
                    name="ccNumber"
                    class="form-control"
                    placeholder="XXXXXXXXXXXXXXXX"
                    aria-label="Credit Card Number"
                    maxlength="16"
                    [ngClass]="{'is-invalid': ccDetails.ccNumber.isInValid}"
                    [(ngModel)]="ccDetails.ccNumber.value"
                    (blur)="checkCCValidation($event)"
                    aria-describedby="basic-addon1">
                    <div class="invalid-feedback">
                      Required. Invalid Card Number
                    </div>
            </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="form-group mb-3">
                  <label class="col-form-label f-16">CVC*</label>
                  <input 
                      type="text"
                      name="ccCvv"
                      class="form-control"
                      placeholder="Eg. 784"
                      aria-label="CVV number"
                      maxlength="3"
                      [ngClass]="{'is-invalid': ccDetails.ccCvv.isInValid}"
                      (blur)="checkCCValidation($event)"
                      [(ngModel)]="ccDetails.ccCvv.value"
                      aria-describedby="basic-addon1">
                      <div class="invalid-feedback">
                          CVC Required
                      </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="form-group mb-3">
              <label class="col-form-label f-16">Month</label>
              <select
                      name="ccExpireMonth"
                      class="form-control"
                      placeholder="Eg. January"
                      aria-label="Expiration Month"
                      (change)="checkCCValidation($event)"
                      aria-describedby="basic-addon1"
                      [ngClass]="{'is-invalid': ccDetails.ccExpireMonth.isInValid}"
                      [(ngModel)]="ccDetails.ccExpireMonth.value"
                      >
                        <option class="p-10" [value]="'-1'"></option>
                          <option 
                              class="p-10"
                              *ngFor="let option of months; index as optionIndex"
                              [value]="optionIndex">
                          {{ option }}
                          </option>
                  </select>
                  <div class="invalid-feedback">
                    Month Required.
                </div>
          </div></div>
          <div class="col-12 col-sm-4">
            <div class="form-group mb-3">
            <label class="col-form-label f-16">Year</label>
            <input 
              type="text"
              name="ccExpireYear"
              class="form-control"
              placeholder="YY"
              maxlength="2"
              [ngClass]="{'is-invalid': ccDetails.ccExpireYear.isInValid}"
              (blur)="checkCCValidation($event)"
              [(ngModel)]="ccDetails.ccExpireYear.value"
              aria-label="Credit Card Expiration Year"
              aria-describedby="basic-addon1">
              <div class="invalid-feedback">
                Year Required.
            </div>
        </div>
      </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 text-center">
        <div class="mt-4">
          <img
            class="img-fluid" 
            src="assets/images/ecommerce/card.png"
            alt="credit card"
          />
      </div>
    </div>
    </div>
    </div>
    <ng-container *ngIf="!commonService.expiredTokenError && paymentService.paymentData.postResErrorCollection.length > 0">
      <div *ngFor="let error of paymentService.paymentData.postResErrorCollection" class="text-danger">
        {{error}}
      </div>
    </ng-container>
    <div *ngIf="!commonService.expiredTokenError && paymentService.paymentData.postResError" class="text-danger">
       Transaction failure. Retry !!!
    </div>
    <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
      <div class="loader-box">
          <div class="loader-9"></div>
      </div>
    </div>
    <div *ngIf="commonService.expiredTokenError" class="text-danger">
      {{commonService.expiredTokenError}}
    </div>
    <div class="form-group mt-3 text-end">
      <button class="btn btn-light btn-lg mx-3" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="previous()">Back</button>
      <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="makePayment()">Pay and Continue</button>
    </div>
    </div>
    </ng-container> 
  
</div>


<ng-template #errorAddressModal let-modal>
  <div class="modal-body">
    <div class="close-modal p-absolute" (click)="modal.close()">
      <i class="icon-close"></i>
    </div>
      <div class="theme-form p-20">
          <div class="text-center img-wrapper m-b-20">
              <img class="border-top-left-radius" src="./assets/images/other-images/sad.png" alt="Image">
           </div>
           
           <ng-container *ngIf="errorMessage">
              <div class="text-danger f-16">{{errorMessage}}</div>
          </ng-container>
          <ng-container *ngIf="errorMessageInArray.length > 0">
            <div *ngFor="let errMsg of errorMessageInArray; index as errorIndex" [id]="errorIndex" class="text-danger f-16">{{errMsg}}</div>
          </ng-container>
      </div>
  </div>
</ng-template>
