  <div class="login-main register-form">
    <h4 class="font-primary">Cart</h4>
    <div class="card-body">
      <div class="row">
        <div class="order-history table-responsive wishlist cart-page">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Product</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container 
                *ngFor="let plan of deviceService.dataPlanDevice.planItem">
                <tr>
                  <td class="text-center">
                    <img
                      class="img-fluid img-40"
                      src="assets/images/product/1.png"
                      alt="#"
                    />
                  </td>
                  <td>
                    <div class="product-name">
                      {{plan.name}}
                    </div>
                  </td>
                  <td>${{plan.price}}</td>
                  <td>
                    <div class="qty-box">
                      <div class="input-group">
                        <button
                          class="btn btn-primary btn-square bootstrap-touchspin-down"
                          type="button"
                          data-type="minus"
                          disabled="true"
                        >
                          <app-feather-icons
                            [icon]="'minus'"
                          ></app-feather-icons>
                        </button>
                        <input
                          class="touchspin text-center form-control"
                          type="text"
                          name="quantity"
                          value="1"
                          name="inputField"
                          [disabled]="true"
                        />
                        <button
                          class="btn btn-primary btn-square bootstrap-touchspin-up"
                          type="button"
                          data-type="plus"
                          [disabled]="true"
                        >
                          <app-feather-icons
                            [icon]="'plus'"
                          ></app-feather-icons>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>${{plan.price}}</td>
                </tr>


                </ng-container>
              <ng-container *ngFor="let device of deviceService.dataPlanDevice.allDevices; index as planIndex; let isLast = last">
              <ng-container *ngIf="deviceService.checkSelectedDevices(device.comboId)">
                  <tr>
                      <td>
                        <img
                          class="img-fluid img-40"
                          src="assets/images/product/1.png"
                          alt="#"
                        />
                      </td>
                      <td>
                        <div class="product-name">
                          {{device.comboName}}
                        </div>
                      </td>
                      <td>${{device.comboPrice}}</td>
                      <td>
                        <div class="qty-box">
                          <div class="input-group">
                            <button
                              class="btn btn-primary btn-square bootstrap-touchspin-down"
                              type="button"
                              data-type="minus"
                              (click)="decrement(device)"
                            >
                              <app-feather-icons
                                [icon]="'minus'"
                              ></app-feather-icons>
                            </button>

                            <input
                              class="touchspin text-center form-control"
                              type="text"
                              name="quantity"
                              [(ngModel)]="inputValue"
                              name="inputField"
                              [disabled]="true"
                            />

                            <button
                              class="btn btn-primary btn-square bootstrap-touchspin-up"
                              type="button"
                              data-type="plus"
                              [disabled]="disableIncButton(device.maxSelection)"
                              (click)="increment(device)"
                            >
                              <app-feather-icons
                                [icon]="'plus'"
                              ></app-feather-icons>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>${{device.comboPrice}}</td>
                  </tr>
              </ng-container>
              </ng-container>
              <ng-container *ngIf="deviceService.dataPlanDevice.unlocksOtherCombos.length > 0">
              <ng-container 
                *ngFor="let subDevice of deviceService.dataPlanDevice.unlocksOtherCombos">
                <tr *ngIf="subDevice">
                  <td>
                    <img
                      class="img-fluid img-40"
                      src="assets/images/product/1.png"
                      alt="#"
                    />
                  </td>
                  <td>
                    <div class="product-name">
                      {{subDevice.comboName}}
                    </div>
                  </td>
                  <td><del>${{subDevice.comboPrice}}</del></td>
                  <td>
                    <div class="qty-box">
                      <div class="input-group">
                        <button
                          class="btn btn-primary btn-square bootstrap-touchspin-down"
                          type="button"
                          data-type="minus"
                          disabled="true"
                        >
                          <app-feather-icons
                            [icon]="'minus'"
                          ></app-feather-icons>
                        </button>

                        <input
                          class="touchspin text-center form-control"
                          type="text"
                          name="quantity"
                          [(ngModel)]="subInputValue"
                          name="inputField"
                          [disabled]="true"
                        />
                        <button
                          class="btn btn-primary btn-square bootstrap-touchspin-up"
                          type="button"
                          data-type="plus"
                          [disabled]="true"
                        >
                          <app-feather-icons
                            [icon]="'plus'"
                          ></app-feather-icons>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td><del>${{subDevice.comboPrice}}</del></td>
                </tr>
              </ng-container>
              </ng-container>

                <tr>
                <td colspan="3">
                </td>
                <td class="total-amount">
                    <h6 class="m-0 text-end">
                    <span class="f-w-600">Total Price :</span>
                    </h6>
                </td>
                <td><span> ${{ getTotalValue() }} </span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group m-t-20 text-end">
          <button class="btn btn-light btn-lg mx-3" (click)="previous()">Back</button>
          <button class="btn btn-primary btn-lg" (click)="slideNext()">Checkout</button>
        </div>
        
      </div>
    </div>
</div>
  
    