import {Injectable} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
  
import { LocalStorage } from 'ngx-webstorage';
import { IBeneficiaryReq } from "../model/beneficiary.model";
import { environment } from "src/environments/environment"; 
import { Observable, ReplaySubject, map } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class BeneficiaryService {
  protected baseApi: string;
  protected token: string; 

  private myBeneficiarySubject = new ReplaySubject<HttpResponse<any>| null>(null);
  myData$ = this.myBeneficiarySubject.asObservable();

  @LocalStorage()
  protected uuid;
  
  @LocalStorage()
  protected xExpire;

    constructor(
        private http:HttpClient,
        ) {
          this.baseApi = environment.BASE_API; 
        }

  put(req: IBeneficiaryReq):Observable<any> { 
   
   return this.http.put(`${this.baseApi}/${environment.AUTH.ADD_BENEFICIARY}/${this.uuid}`, 
    req, { observe: 'response'})
    .pipe(
      map(
        (response:HttpResponse<any>) => {
        if(response.status === 200 && response.body.Status){
          this.myBeneficiarySubject.next(response.body)
        }
        return response
      })
  )}
}

