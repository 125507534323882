import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-list-housing',
  templateUrl: './list-housing.component.html',
  styleUrls: ['./../list-program.component.scss']
})
export class ListHousingComponent implements OnInit {

  
  @Input()
  public program;

  @Input()
  public index;

  public FPH: any[];
  
  constructor(
    public programService: ProgramServices,
    public commonService: CommonService
  ){}

  ngOnInit():void {
    this.FPH = this.program.subProgram;
  }
  
  toggleClass(programName):boolean {
    const dataSelected = this.programService.checkSelected(programName);
    console.log(this.programService.fphSelected);
    console.log(dataSelected);
    console.log(this.programService.fphInvalidCard);

    console.log(this.programService.fphSelected && dataSelected);
    console.log(this.programService.fphSelected && dataSelected && this.programService.fphInvalidCard);

    return  (this.programService.fphSelected && dataSelected && this.programService.fphInvalidCard);
  }
}
