import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { QuestionServices } from 'src/app/shared/services/questions.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})

export class QuestionComponent {

  public errorMessage: string; 
  public apiProcess:boolean = false;
  public formSubmitted: boolean = false;
  private timeOutId: number = -1;

  public questionName:string = '';
  public questionDesc:string = '';
  public questionID:string = '';

  private answerValue:string = '';
  private userID: number;
  private subscription: Subscription;

  @LocalStorage()
  prefilledInfo;

  public answers = [
    {
      answerLabel: 'Yes',
      answerValue: 'yes',
      isSelected: false,
    },
    {
      answerLabel: 'No',
      answerValue: 'no',
      isSelected: false,
    }
  ]
  
  constructor(
    private questionServices: QuestionServices,
    private cookieService: CookieService,
    public commonService: CommonService,
    private router: Router,
    private localStorage: LocalStorageService
    ){
      this.commonService.setStepInfo('registration/question');
    }


  ngOnInit(): void {
    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);

    this.userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID: this.userID,
      questionID: null
    }

    this.questionServices.put(payload).subscribe(
      (response) => {
          window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.commonService.expiredTokenError = "";
          if(response.status === 200 && response.body.Status){
            //TODO
            const {questionName, questionDesc, questionID} = response.body.data.questions[0];
            this.questionName = questionName;
            this.questionDesc = questionDesc;
            this.questionID = questionID;
          }
          else {
            this.errorMessage = response.body.message;
          }
      },
      (error => {
        window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.commonService.expiredTokenError = "";
          if(!error.error || !error.error.message){
            error.error.message = "Unknown error"
          }
          this.errorMessage = error.error.message;
      })
    )

    
  }

  renderAdditionalForm(event){
    this.answerValue = event.currentTarget.id;
  }
   
  submit(){
    
    this.errorMessage = '';
    // this.errorMessage = "Please choose the radio."
    
    // this.timeOutId = window.setTimeout( () => {
        this.apiProcess = true;
      // }, 1200);
      this.formSubmitted = true;
      const userID = +this.cookieService.get('x-userId');

      const ans = this.answers.find( (value) =>  value.isSelected);

      const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        questionID: this.questionID,
        answer: this.answerValue 
      }

      this.questionServices.put(payload).subscribe(
        (response) => {
          // window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.commonService.expiredTokenError = "";
          if(response.status === 200 && response.body.Status){
            const {currentStep} = response.body.data;

            const resultData = response.body.data;
            if(this.prefilledInfo){
              this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
              this.localStorage.store('prefilledInfo', this.prefilledInfo);
            }
            if(resultData.enrollmentNumber){
              this.commonService.updateEnrollment(resultData.enrollmentNumber);
            }

            if(currentStep === 7){
              const {questionName, questionDesc, questionID} = resultData.questions[0];
              this.questionName = questionName;
              this.questionDesc = questionDesc;
              this.questionID = questionID;
              this.answers.forEach( ans => {
                ans.isSelected = false;
              })
            }
            const nextRoute = this.commonService.setRouter(resultData.currentStep);
            this.router.navigate([nextRoute]);
          }
          else {
            this.errorMessage = response.body.error["answer"];
          }
        },
        (
          (error) => {
            // window.clearTimeout(this.timeOutId);
            this.commonService.expiredTokenError = "";
            this.apiProcess = false;
            if(!error.error && !error.error.message ){
              error.error.message = "Unknown error";
            }
            this.errorMessage = error.error.message;
          }
        )
      )
  }
  
  previous(){
    this.router.navigate(['registration/address']);
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
