import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from 'ngx-webstorage';
import { FORM_CC } from 'src/app/shared/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { PaymentServices } from 'src/app/shared/services/payment.services';
import { PlanServices } from 'src/app/shared/services/plan_device.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public billingInformation;
  public shippingInformation;
  public totalPaymentAmount; 
  public apiProcess:boolean = false;
  private timeOutId: number = -1;
  private ccYear: string;
  public errorMessage: string = '';
  public errorMessageInArray: string[] = [];

  public ccDetails = {
    ccName: {
      label: '',  
      value: '',
      isInValid: false
    },
    ccNumber: {
      label: '',
      value: '',
      isInValid: false
    },
    ccExpireMonth: {
      label: '',
      value: '',
      isInValid: false
    },
    ccExpireYear: {
      label: '',
      value: '',
      isInValid: false
    },
    ccCvv: {
      label: '',
      value: '',
      isInValid: false
    }
  }
  
  public months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  @LocalStorage()
  private stepsInfo;

  @ViewChild('errorAddressModal') modelAddTemplate;

  constructor(
    public deviceService: PlanServices,
    public commonService: CommonService,
    public paymentService: PaymentServices,
    private cookieService: CookieService,
    private router: Router
  ){
    this.commonService.setStepInfo('registration/payment');
  }

  ngOnInit(){ 

    const userID = +this.cookieService.get('x-userId');
    this.paymentService.getPaymentReq({
      userID,
      companyId: environment.COMPANY_ID
    }).subscribe(
        (response) => {
          this.paymentService.paymentData.getCallerror = "";
          if(response.status === 200 && response.body.Status){
            this.paymentService.paymentData.getResData = response.body["data"];
            // if(response.body["data"].totalPrice !== 0){
            //   this.router.navigate(['registration/payment']);
            // }
            // else {
            //   this.router.navigate(['registration/esign']);
            // }
          }
          else {
            this.paymentService.paymentData.getCallerror = response.body.message;
            console.warn("get payment endpoint fails")
          }
        },
        (error) => {
          this.paymentService.paymentData.getCallerror = error.message;
          console.warn("get payment endpoint fails")
        }
    );
  }

  checkCCValidation(event): void{
    const date = new Date();
     if(event.target.name === 'ccName'){
        const {value} = this.ccDetails.ccName;
      if(!value){
        this.ccDetails.ccName.isInValid = true; 
       }
      else if(value.length >= 2){
        this.ccDetails.ccName.isInValid = false; 
       }
      else {
        this.ccDetails.ccName.isInValid = false;
      }
     }
     else if(event.target.name === 'ccNumber'){
      const {value} = this.ccDetails.ccNumber;
       if(!value){
        this.ccDetails.ccNumber.isInValid = true;
       }
       else if(value.length !== 16){
        this.ccDetails.ccNumber.isInValid = true;
       }
       else {
        this.ccDetails.ccNumber.isInValid = false;
       }
     }

     else if(event.target.name === 'ccCvv'){
      const {value} = this.ccDetails.ccCvv;
       if(!value){
        this.ccDetails.ccCvv.isInValid = true
       }
       else if(! FORM_CC.CVV.test(value)){
        this.ccDetails.ccCvv.isInValid = false
       }
       else {
        this.ccDetails.ccCvv.isInValid = false;
       }
     }

     else if(event.target.name === 'ccExpireYear'){
      
      const year = date.getFullYear();
      const month = date.getMonth();

      const {value} = this.ccDetails.ccExpireYear;
      const yearPrefix = `${year}`.substring(0, 2);
       if(!value){
        this.ccDetails.ccExpireYear.isInValid = true
        this.ccYear = '';
       }
       else if(value){
         if(+value < year){
          this.ccDetails.ccExpireYear.isInValid = false;
          this.ccYear = `${yearPrefix}${value}`;
         }
         else {
          this.ccDetails.ccExpireYear.isInValid = false;
          this.ccYear = `${yearPrefix}${value}`;
         }
       }
     }
     else if(event.target.name === 'ccExpireMonth'){
      const {value} = this.ccDetails.ccExpireMonth;
       if(!value){
        this.ccDetails.ccExpireMonth.isInValid = true
       }
       else if( value === '-1'){
        this.ccDetails.ccExpireMonth.isInValid = true
       }
       else {
        this.ccDetails.ccExpireMonth.isInValid = false;
       }
     }
  }

  ccSaveValidation(){
    const isInValid = [];
    for(const [key, value] of Object.entries(this.ccDetails)){
      if(!value.value){
        this.ccDetails[key].isInValid = true;
        isInValid.push('true');
      }
      isInValid.push('false');
    }
    return isInValid.includes('true');
  }

  makePayment(){
      this.makePaymentUsingCC();
  }

  loadCheckout(){
    if(this.paymentService.paymentData.getResData){
      this.billingInformation = this.paymentService.paymentData.getResData["billingInformation"];
      this.shippingInformation = this.paymentService.paymentData.getResData["shippingInformation"];
      this.totalPaymentAmount = this.paymentService.paymentData.getResData["totalPaymentAmount"];
      return true;
    }
    return false;
  }

makePaymentUsingCC(){
  
  let invalidCard = false;
  const userID = +this.cookieService.get('x-userId');

  const payload = {
      companyId: environment.COMPANY_ID,
      userID,
      returnUrl: environment.AUTH.CHECKOUT_RETURN_URL
    }
    
    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);

    if(this.totalPaymentAmount === 0){
      this.paymentService.paymentData.postResError = "";
      const payloadData = Object.assign(payload, {
        customerIP: this.deviceService.ipAddress
      });

      this.paymentService.makeZeroPayment(payloadData).subscribe(
        response => {
          
          if(response.status === 200 && response.body.Status){
            this.paymentService.paymentData.postResData = response.body["data"];
            this.apiProcess = false;
            this.router.navigate(['registration/esign'])
          }
          else {
            this.apiProcess = false;
          }
        },
        error => {
          this.apiProcess = false;
          if(!error.error && !error.error.message ){
            error.error.message = "Unknown error";
          }
          this.commonService.open(this.modelAddTemplate, 'md');
          this.errorMessage = error.error.message;
        }
      )
    }
  else if(this.totalPaymentAmount > 0){

    const checkGuard = this.ccSaveValidation();
    if(!checkGuard){ 
      this.apiProcess = false;
      invalidCard = true;
    }

    for(const [, value] of Object.entries(this.ccDetails)){
      if(value.isInValid){
        this.apiProcess = false;
        invalidCard = true;
        break;
      }
    }

    const {ccNumber, ccName, ccCvv, ccExpireMonth } = this.ccDetails;
    const userID = +this.cookieService.get('x-userId');

    if(!invalidCard){
      this.apiProcess = false;
    }
    
    const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        createTransactionRequest: {
            refId: "12123",
            transactionRequest: {
                amount: this.totalPaymentAmount,
                payment: {
                    creditCard: {
                      cardNumber: ccNumber.value,
                      expirationDate: `${this.ccYear}-${+ccExpireMonth.value + 1}`,
                      cardCode: ccCvv.value,
                      nameOnCard: ccName.value
                    }
                },
                customerIP: this.deviceService.ipAddress,
                transactionSettings: {
                    setting: {
                        settingName: "",
                        settingValue: ""
                    }
                },
                subsequentAuthInformation: {
                    originalNetworkTransId: "",
                    originalAuthAmount: "",
                    reason: ""
                }
            }
        }
      }
      
      this.paymentService.post(payload).subscribe(
        (response) => {
          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.errorMessageInArray = [];
          this.paymentService.paymentData.postResError = "";
          this.paymentService.paymentData.postResErrorCollection = [];
          this.apiProcess = false;
          if(response.status === 200 && response.body.Status){
            this.paymentService.paymentData.postResData = response.body["data"];
            this.router.navigate(['registration/esign'])
          }
          else {
            if(typeof response.body.error === 'object'){
               const data = this.commonService.getErrorMessageArranged(response.body.error);
               this.paymentService.paymentData.postResErrorCollection = data
            }
            else if(typeof response.body.error === 'string'){
              this.paymentService.paymentData.postResError = response.body.error;
              this.paymentService.paymentData.postResErrorCollection = []
            }
          }
        },
        (error) => {
          this.errorMessageInArray = [];
          window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.commonService.expiredTokenError = "";
          if(!error.error ){
            this.paymentService.paymentData.postResError = "Unknown error";
          }
          else {
            this.paymentService.paymentData.postResError = error.error.message;
          }

          this.commonService.open(this.modelAddTemplate, 'md');
          this.errorMessage = this.paymentService.paymentData.postResError;

          this.paymentService.paymentData.postResErrorCollection = [];

          if(error.error.errorMsg){
            for( const [key, value] of Object.entries(error.error.errorMsg)){
              if(key === 'status'){
                continue;
              }
              else {
                const dynamicError = `${key} :: ${value}`;
                this.errorMessageInArray.push(dynamicError);
                // this.paymentService.pymentData.postResErrorCollection.push(dynamicError)
              }

            }
          }

          if(!error.error){
            this.paymentService.paymentData.postResError = "Unknown error";
          }
          else {
            this.paymentService.paymentData.postResError = error.error.message;
          }
        }
      )
    }
    // this.deviceService.slideNext()
  } 

  previous(){
    const stepData = this.stepsInfo.find( item => item.Step === 15);
    const nextRoute = this.commonService.setRouter(stepData.PrevStep);
    this.router.navigate([nextRoute]);
    // this.router.navigate(['registration/cart'])
  }
}
