import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-list-income',
  templateUrl: './list-income.component.html',
  styleUrls: ['./../list-program.component.scss']
})
export class ListIncomeComponent implements OnInit {

  @ViewChild('confirmSelectedIncome') modalTemplate; 
  
  @Input()
  public program;

  @Input()
  public index;
  
  public IB;

  constructor(
    public programService: ProgramServices,
    public commonService: CommonService,
    private modalService: NgbModal
  ){}

  ngOnInit(): void {
    this.IB =  this.program.incomeBaseProgramMapping;
  }

  userResponseOnIncome(string):void{
    this.programService.updateIBUserRes(string);
    this.programService.showIBConfirmBox = false;
    this.programService.saveIBForm('save and close');
    this.modalService.dismissAll('close');
  }
}
