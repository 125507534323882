import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { FORM_CONST } from 'src/app/shared/constants';
import { ServiceAvailability } from 'src/app/shared/services/network.services';
import { environment } from "src/environments/environment";
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { HttpResponse } from '@angular/common/http';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { RecaptchaService } from 'src/app/shared/services/recaptcha.service';


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})

export class NetworkComponent implements OnInit, OnDestroy {

  //captcha
  public gSiteKey: string;
  public registerForm:FormGroup;
  public checkZipSignal: string;
  public errorMessage: string;
  private userID: number;
  public overallServiceStrength : string;
  private timeOutId: number = -1;


  // public invalidCredential: boolean = false;
  public apiProcess:boolean = false;
  // public formSubmitted: boolean = false;

 // login related things

  public invalidCredential;
  public authErrorMessage: string;

  public captchaSiteKey: string;

  private subscription: Subscription;
  private subscriptionMyData: Subscription;
  private subscriptionNetworkData: Subscription;
  private subscriptionInterval: Subscription;

  public nextRoute: string;
  public captchaValidation: boolean = false;

  // public resumeInfo?: IResumeInfo;

  @ViewChild('resumeEnrollment') modalTemplate;

  public responseInfo: any | null;

  @LocalStorage()
  private zipCode;

  @LocalStorage()
  private email;

  resume:any;


  constructor(
    private fb: FormBuilder,
    private networkServices: ServiceAvailability,
    private localStorage : LocalStorageService,
    public commonService: CommonService,
    private cookieService: CookieService,
    private modalService: NgbModal,
    private router: Router,
    private recaptchaService: RecaptchaService,
    ){

      this.userID = +this.cookieService.get('x-userId');
      this.captchaSiteKey = environment.G_CAPTCHA_SITE_KEY;

    // this.gSiteKey = environment.G_CAPTCHA_SITE_KEY;

    this.registerForm = this.fb.group({
      zipCode: ['', [
        Validators.pattern(FORM_CONST.ZIP_CODE_PATTERN),
        Validators.required
        ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      // recaptchaReactive: ['', [
      //   Validators.required
      // ]]
    });


  }

  ngOnInit(){
    const resumeData = localStorage.getItem('resume');
    if(resumeData){
      this.resume =JSON.parse(resumeData)
    }
    if(this.resume){
      this.registerForm.patchValue({
        zipCode: this.resume.zipCode,
        email:  this.resume.email
      });
      this.onSubmitRegister('N');
      localStorage.removeItem('resume');
    }

    this.commonService.expiredTokenError = "";
    this.login();

    if(this.zipCode || this.email){
      this.registerForm.patchValue({
        zipCode: this.zipCode,
        email:  this.email
      })
    }
    this.networkServices.getStepper().subscribe(
      (response) => {
        if(response.Status){
          this.localStorage.store('stepsInfo', response.data)
        }
      },
      (error) => {
        console.warn(error);
      }
    )
  }

  onSubmitRegister(userConfirm){
    let captchaToken;
    this.recaptchaService.getReCaptchaToken('submit').then(token => {
      captchaToken = token;
      this.captchaValidation = true;

    }).catch(error => {
      this.captchaValidation = false;
      console.log(error);
      return;
    });

    if(this.registerForm.status !== "INVALID"){

      this.timeOutId = window.setTimeout( () => {
        this.apiProcess = true;
      }, 1200);

      this.errorMessage = '';

      const { zipCode, email } = this.registerForm.value;

      const payload = {
        zipCode,
        email,
        companyId: environment.COMPANY_ID,
        source: "WebBrowser",
        enrolmentType: "Enroll",
        userID: this.userID,
        restartEnroll: userConfirm,
        captchaToken
      }
      if((this.email !== payload.email) && (this.zipCode !== payload.zipCode)){
        this.commonService.clearLocalStorageWithoutExpireTime();
      }

      this.subscription =  this.networkServices.post(payload).subscribe
        (
          (response: HttpResponse<any>) => {
            window.clearTimeout(this.timeOutId);
            this.commonService.expiredTokenError = "";
              if(response.status === 200 && response.body.Status){
                  const resultData = response.body.data[0];

                  if(resultData.resumeInfo.resumeAvailable){
                    this.commonService.open(this.modalTemplate, 'md');
                  }

                  if(!resultData.resumeInfo.resumeAvailable){
                    this.commonService.clearLocalStorageWithoutExpireTime();
                  }

                  if(resultData["steps"]){
                    this.commonService.settingStorageOnNetworkCall(resultData.steps)
                  }

                  this.nextRoute = this.commonService.setRouter(resultData.resumeInfo.currentStep);
                  this.errorMessage = '';
                  const uuid = resultData.EnrollmentCode;
                  if(resultData.enrollmentNumber){
                    this.commonService.updateEnrollment(resultData.enrollmentNumber);
                  }

                  this.localStorage.store('uuid', uuid);
                  this.overallServiceStrength = resultData.overallServiceStrength;
                  this.localStorage.store('zipCode', zipCode);
                  this.localStorage.store('email', email);
                  this.localStorage.store('zipCodeId', resultData["id"]);
                  this.apiProcess = false;
                  if(!resultData.resumeInfo.resumeAvailable){
                    this.router.navigate(['registration/personal'])
                  }

                  const storeData = Object.assign({}, response.body.data[0]);

                  this.commonService.deleteObjectKeys(['EnrollmentCode', 'serviceStrength', 'overallServiceStrength', 'serviceStrength'], storeData);
                  this.localStorage.store('prefilledInfo', storeData);
              }
            else {
              this.errorMessage = response.body.message;
              this.apiProcess = false;
              // this.formSubmitted = false;
            }
          },
          (err) => {
            window.clearTimeout(this.timeOutId);
            this.apiProcess = false;
            this.commonService.expiredTokenError = "";
            if(!err.error || !err.error.message){
              err["error"] = {
                message: "Unknown error"
              }
            }
            this.errorMessage = err.error.message;
          }
      );
     }
    else {
      this.registerForm.markAllAsTouched();
    }
  }

  registrationCancel(){
   this.onSubmitRegister('Y');
   this.modalService.dismissAll('none');
  }
  resumeWithPrevious(){
    this.modalService.dismissAll('none');
    this.router.navigate(['registration/personal']);
  }

  login(){
    const payload = {
     userName: environment.AUTH.USER_NAME,
     password: environment.AUTH.USER_PWD,
     companyId: environment.COMPANY_ID
    }

   const expires: Date = new Date();
   this.commonService.clearLocalStorage();

    this.commonService.authCall(payload).subscribe(
     (response) => {
        this.commonService.expiredTokenError = "";

       if(response.status === 200 && response.body.Status){
         // code for setting expiration in cookies
         const currentMinute = expires.getMinutes();
         expires.setMinutes(currentMinute +  environment.TOKEN_EXPIRATION_MINUTES + 30);
         const jwt = response.body["token"];
         const refreshToken = response.body["data"][0].refreshToken;
         const userId = response.body["data"][0].userID;
         this.invalidCredential = false;
         this.cookieService.set("x-token", jwt, {expires: expires });
         this.cookieService.set("x-refresh-token", refreshToken );
         this.cookieService.set("x-userId", userId);
         this.localStorage.store('xExpire', expires);
       }
       else {
         this.authErrorMessage = response.body.message;
       }
     },
     (err) => {
      this.commonService.expiredTokenError = "";
      if(!err.error || !err.error.message){
         err["error"] = {
          message: "Unknown error"
        }
      }
      this.authErrorMessage = err.error.message;
      this.invalidCredential = true;
     }
    );
 }

  ngOnDestroy() {
    if(this.subscriptionMyData){
      this.subscriptionMyData.unsubscribe();
    }
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionNetworkData){
      this.subscriptionNetworkData.unsubscribe();
    }
    if(this.subscriptionInterval){
      this.subscriptionInterval.unsubscribe();
    }
  }
}
