import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlanServices } from 'src/app/shared/services/plan_device.services';

@Component({
  selector: 'app-sub-plan',
  templateUrl: './sub-plan.component.html',
  styleUrls: ['./sub-plan.component.scss']
})
export class SubPlanComponent {

  @Input()
  public plan;

  constructor(
    public planService: PlanServices
  ){}

  selectPlan(planId){
    this.planService.selectCard(planId) 
  }

}
