import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(private http: HttpClient) {}

  async getReCaptchaToken(action: string): Promise<string> {
    return new Promise<string>((resolve) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(environment.G_CAPTCHA_SITE_KEY, { action: action }).then((token: string) => {
          resolve(token);
        });
      });
    });
  }
}
