import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step1RoutingModule } from './step1-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalComponent } from './personal/personal.component';
import { SecurityComponent } from './security/security.component';
import { ApplicantAddressComponent } from './applicant-address/applicant-address.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { QuestionComponent } from './question/question.component';

import { SharedModule } from 'src/app/shared/shared.module';

import { PlansComponent } from './plans/plans.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceCartComponent } from './device-cart/device-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SubPlanComponent } from './plans/sub-plan/sub-plan.component';
import { SubDeviceComponent } from './devices/sub-device/sub-device.component';
import { PreviewComponent } from './preview/preview.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EsignComponent } from './esign/esign.component';
import { ReviewComponent } from './review/review.component';
import { CompleteComponent } from './complete/complete.component';
import { ListProgramComponent } from './list-program/list-program.component';
import { ListIncomeComponent } from './list-program/list-income/list-income.component';
import { ListSchoolLunchComponent } from './list-program/list-school-lunch/list-school-lunch.component';
import { ListHousingComponent } from './list-program/list-housing/list-housing.component';


@NgModule({
  declarations: [
    PersonalComponent,
    SecurityComponent,
    ApplicantAddressComponent,
    BeneficiaryComponent,
    QuestionComponent,
    PlansComponent,
    DevicesComponent,
    DeviceCartComponent,
    CheckoutComponent,
    SubPlanComponent,
    SubDeviceComponent,
    PreviewComponent,
    EsignComponent,
    ReviewComponent,
    CompleteComponent,
    ListProgramComponent,
    ListIncomeComponent,
    ListSchoolLunchComponent,
    ListHousingComponent,
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Step1RoutingModule,
    BsDatepickerModule,
    SharedModule,
    NgSelectModule
  ],
  exports: [
    PersonalComponent,
    SecurityComponent,
    ApplicantAddressComponent,
    BeneficiaryComponent,
    QuestionComponent, 
    PlansComponent,
    DevicesComponent,
    DeviceCartComponent,
    CheckoutComponent,
    SubPlanComponent,
    SubDeviceComponent,
    PreviewComponent,
    ReviewComponent,
    EsignComponent,
    ListProgramComponent,
    ListIncomeComponent,
    ListSchoolLunchComponent,
    ListHousingComponent,
  ]
})
export class Step1Module { }
