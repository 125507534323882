<div class="container-fluid">
<div class="container bg-white">
    <div id="stepper1" class="bs-stepper pt-4" #stepperRef>
      <div class="bs-stepper-header sticky-stepper-header" role="tablist">
        <div class="step" data-target="#test-l-1">
          <button class="step-trigger" [disabled]="disableStepper1">
            <span class="bs-stepper-circle f-16">1</span>
            <span class="bs-stepper-label f-14">Personal Information</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#test-l-2">
          <button class="step-trigger" [disabled]="disableStepper2">
            <span class="bs-stepper-circle f-16">2</span>
            <span class="bs-stepper-label f-14">Eligibility</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#test-l-3">
          <button class="step-trigger" [disabled]="disableStepper3">
            <span class="bs-stepper-circle f-16">3</span>
            <span class="bs-stepper-label f-14">Plans</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#test-l-4">
            <button class="step-trigger" [disabled]="disableStepper4">
              <span class="bs-stepper-circle f-16">4</span>
              <span class="bs-stepper-label f-14">Previews</span>
            </button>
          </div>
      </div>
      <div class="text-center enroll-label" *ngIf="enrollmentIdStorage">
        <p class="f-12">
          <a class="btn btn-primary btn-small" (click)="clipboard(enrollmentIdStorage)">Your enrollment ID: {{enrollmentIdStorage}}</a>
          <ng-container *ngIf="commonService.isTextCopied"><br/>
            Copied.
          </ng-container>
        </p>
      </div>
      <div class="bs-stepper-content">
        <div id="test-l-1" class="content d-none"></div>
        <div id="test-l-2" class="content d-none"></div>
        <div id="test-l-3" class="content d-none"></div>
        <div id="test-l-4" class="content d-none"></div>
      </div>
  </div>
  <router-outlet></router-outlet> 
</div>