import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./../review.scss']
})
export class ReviewComponent {

  @LocalStorage()
  public enrollmentIdStorage;
  
  @LocalStorage()
  public prefilledInfo;
  

  public enrollMsg: string = 'complete' 

  constructor(
    public commonService: CommonService,
    private router: Router
  ){
    this.commonService.setStepInfo('registration/complete');
  }
  
  completed(){
    this.router.navigate([''])
  }

}


