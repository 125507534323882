import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./../../shared/header.scss']
})
export class HeaderComponent {
  @ViewChild('headerRef') header: ElementRef;

  private stickyClass1: string = 'site-header--sticky';
  private stickyClass2: string = 'site-header--opening';
  private scrollOffset: number = 100; // Change this based on when you want the header to become sticky
  
  @HostListener('window:scroll', ['$event'])

  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset >= this.scrollOffset) {
        this.header.nativeElement.classList.add(this.stickyClass1);
        this.header.nativeElement.classList.add(this.stickyClass2);
    } else {
        this.header.nativeElement.classList.remove(this.stickyClass1);
        this.header.nativeElement.classList.remove(this.stickyClass2);
    }
  }

  toggleMegaMenu(){
    // this function is applicable for only mobile device
    window.location.href = "https://unitywireless.com/";  
  }
}
