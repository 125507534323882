  <div class="container bg-white custom-p-20 p-relative gte-md-m-t-50">
        <div class="form-group mt-3 p-absolute btn-top-action text-end">
            <button type="submit" class="btn btn-primary btn-lg" [disabled]="apiProcess" (click)="submitPersonalInfoForm()">Next</button>
          </div>
        <h4 class="font-primary">Personal Information</h4>
        <p class="f-16">The name you use on official documents, like your Social Security Card or State ID. Not a nickname.</p>
        <div class="theme-form">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-group mb-3">
                        <label class="col-form-label f-16" for="personalFormFirstName">First Name*</label>
                        <input 
                            type="text"
                            id="personalFormFirstName"
                            name="personalFormFirstName"
                            class="form-control"
                            placeholder="Eg. John"
                            aria-label="First Name"
                            (blur)="checkValidation($event)"
                            [(ngModel)]="personalFirstName.value"
                            [ngClass]="{'is-invalid':personalFirstName.isValid}"
                            aria-describedby="basic-addon1">
                            <div class="invalid-feedback">
                                Minimum 3 and maximum 50 char allowed
                            </div>
                            <div class="text-danger f-12" *ngIf="personalFirstName.isValid">Required. Only alphabets and special characters allowed</div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-group mb-3">
                        <label class="col-form-label f-16" for="personalFormMiddleName">Middle Name</label>
                        <input 
                            type="text"
                            name="personalFormMiddleName"
                            class="form-control"
                            placeholder="Eg. Middle name"
                            aria-label="Middle Name"
                            id="personalFormMiddleName"
                            (blur)="checkValidation($event)"
                            [(ngModel)]="personalMiddleName.value" 
                            aria-describedby="basic-addon1">
                            <!-- <div class="text-danger f-12">{{ middleName.errors["customError"]}}</div> -->
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <div class="form-group mb-3">
                      <label class="col-form-label f-16" for="personalFormLastName">Last Name*</label>
                      <input 
                          type="text" 
                          name="personalFormLastName"
                          id="personalFormLastName"
                          class="form-control"
                          placeholder="Eg. Smith"
                          aria-label="Last Name"
                          aria-describedby="basic-addon1"
                          (blur)="checkValidation($event)"
                          [(ngModel)]="personalLastName.value" 
                          [ngClass]="{'is-invalid':personalLastName.isValid}">
                          <div class="invalid-feedback">
                            Minimum 3 and maximum 50 char allowed
                        </div>
                        <div class="text-danger f-12" *ngIf="personalLastName.isValid">Required. Only alphabets and special characters allowed</div>
                  </div>
              </div>
               <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group mb-3">
                    <label class="col-form-label f-16" for="personalFormSuffix">Suffix</label>
                    <select 
                        name="personalFormSuffix"
                        id="personalFormSuffix"
                        class="form-control"
                        placeholder="Eg. John2"
                        aria-label="Suffix"
                        aria-describedby="basic-addon1"
                        [(ngModel)]="personalSuffix.value"
                        >
                          <option 
                            class="p-10"
                            *ngFor="let option of prefixData; index as optionIndex"
                            [value]="option.value"
                            [id]="'option-'+optionIndex"
                            >
                              {{ option.displayName }}
                          </option>
                    </select>
                    <!-- <div class="text-danger f-12" *ngIf="personalSuffix">{{ suffix.errors["customError"]}}</div> -->
                </div>
            </div> 
            <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group mb-3">
                    <label class="col-form-label f-16" for="personalFormContactNumber">Contact*</label>
                    <input 
                        type="text"
                        name="personalFormContactNumber"
                        id="personalFormContactNumber"
                        class="form-control"
                        [placeholder]="'Eg. ' + contactNumberPlaceHolder"
                        aria-label="Contact Number"
                        aria-describedby="basic-addon1"
                        maxlength="10"
                        (focusin)="hideFormattedContactNumber('personal')"
                        (focusout)="formatPhoneNumberData('personal')"
                        (change)="commonService.checkAlphaAndDelete($event)"
                        [(ngModel)]="personalContactNumber.value"
                        (blur)="checkValidation($event)"
                        [ngClass]="{'is-invalid':personalContactNumber.isValid}">
                        <div class="formatted-data" (click)="hideFormattedContactNumber('personal')" *ngIf="personalShowFormatContactNumber">{{formattedPersonalPhoneNumber}}</div>
                        <div class="invalid-feedback">
                          Contact number is required. It should be 10 digit, start with [2-9].
                        </div>
                  </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <!-- personal -->
                <div class="checkbox p-l-10">
                  <div class="checkbox p-0">
                    <input 
                      (change)="checkValidation($event)"
                      [(ngModel)]="personalSelfConsent.value"
                      [ngClass]="{'is-invalid': personalSelfConsent.isValid}"
                      type="checkbox"
                      [checked]="personalSelfConsent.value"
                      id="personalFormSelfConsent"
                      name="personalFormSelfConsent"
                       />
                      <label class="text-muted f-16 p-l-20" for="personalFormSelfConsent">I authorize</label>
                      <span class="cursor-pointer" (click)="slideStateConsent = slideStateConsent === 'up' ? 'down' : 'up'">
                        &nbsp;&nbsp;See More.
                      </span>
                  </div>
                </div>
              </div>
              <div *ngIf="personalSelfConsent.isValid" class="error-invalid f-12">
                Checkbox is required field.
              </div>
            </div>
            <div [@slideUpDown]="slideStateConsent" class="f-14 m-b-30" >By checking this box and your signature below. You are authorizing Unity Wireless INC, or is representatives to use an automated system to deliver telemarketing calls to the phone number you provided below using an automatic telephone dialing system, and or in artificial or pre-recorded voice or text message. You also agree to receive recurring automated promotional and personalized marketing text messages and emails (e.g. complete your application reminders) from Unity Wireless INC at the cell number used when signing up. Consent is not a condition of any purchase. Message frequency varies.</div>
          </div>
            <!-- <div *ngIf="!commonService.expiredTokenError && errorMessage && !errorCollection" class="error-invalid f-12">
                {{"Error: " + errorMessage}}
            </div> -->
            <!-- <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
                <div  class="loader-box">
                    <div class="loader-9"></div>
                </div>
            </div> -->
            
            <!-- <div class="text-danger" *ngIf="commonService.expiredTokenError">{{ commonService.expiredTokenError }}</div> -->
            <!-- <div class="form-group mt-3 text-end">
              <button type="submit" class="btn btn-primary btn-lg" [disabled]="apiProcess" (click)="submitPersonalInfoForm()">Next</button>
            </div> -->
           
          </div>
     
        <h4 class="font-primary m-t-20">Who received government assistance? <small>(SNAP, Medicaid, etc)</small></h4>
        <div class="theme-form">
        <div class="form-group p-l-10">
          <div class="radio radio-primary p-t-5 m-b-10">
            <input class="form-check-input" 
            name="isApplyingForSelf"
            value="Yes"
            [(ngModel)]="isApplyingForSelf.value"
            id="isApplyingForSelf-1"
            (change)="slideState = slideState === 'up' ? 'down' : 'up'"
            type="radio">
            <label class="form-check-label f-16" for="isApplyingForSelf-1">
              Myself
            </label>
          </div>
          <div class="radio radio-primary p-t-5">
            <input class="form-check-input" 
            name="isApplyingForSelf"
            value="No"
            [(ngModel)]="isApplyingForSelf.value"
            (change)="slideState = slideState === 'up' ? 'down' : 'up'"
            id="isApplyingForSelf-2"
            type="radio">
            <label class="form-check-label f-16" for="isApplyingForSelf-2">
              Somebody else in household
            </label>
          </div>
        </div>
        
          <div [@slideUpDown]="slideState">
          <h5 class="m-b-15 m-t-10 f-18">Qualifying Beneficiary Details</h5>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                  <label for="beneficiaryFormFirstName" class="col-form-label f-16">First Name*</label>
                  <input 
                    type="text"
                    name="beneficiaryFormFirstName"
                    id="beneficiaryFormFirstName"
                    class="form-control"
                    placeholder="Eg. John"
                    aria-label="First Name"
                    (blur)="checkValidation($event)"
                    [(ngModel)]="beneficiaryFirstName.value"
                    [ngClass]="{'is-invalid':beneficiaryFirstName.isValid}"
                    aria-describedby="basic-addon1">
                    <div class="invalid-feedback">
                      Required. Only alphabets and special characters allowed
                  </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                <label class="col-form-label f-16">Middle Name</label>
                <input 
                  type="text"
                  name="middleName"
                  class="form-control"
                  [(ngModel)]="beneficiaryMiddleName.value"
                  placeholder="Eg. Middle name"
                  aria-label="Middle Name"
                  aria-describedby="basic-addon1">
              </div>
            </div>
          
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group mb-3">
                  <label class="col-form-label f-16" for="beneficiaryFormLastName">Last Name*</label>
                  <input 
                    type="text"
                    name="beneficiaryFormLastName"
                    id="beneficiaryFormLastName"
                    (blur)="checkValidation($event)"
                    [ngClass]="{'is-invalid':beneficiaryLastName.isValid}"
                    [(ngModel)]="beneficiaryLastName.value"
                    class="form-control"
                    placeholder="Eg. Smith"
                    aria-label="Last Name"
                    aria-describedby="basic-addon1"
                    >
                    <div class="invalid-feedback">
                      Required. Only alphabets and special characters allowed
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                  <label class="col-form-label f-16" for="beneficiaryFormSuffix">Suffix</label>
                  <select
                      name="beneficiaryFormSuffix"
                      id="beneficiaryFormSuffix"
                      class="form-control"
                      placeholder="Eg. John2"
                      aria-label="Suffix"
                      aria-describedby="basic-addon1"
                      [(ngModel)]="beneficiarySuffix.value"
                      >
                        <option 
                            class="p-10"
                            *ngFor="let option of beneficiaryPrefixData; index as optionIndex"
                            [id]="'beneficiary-option' + optionIndex"
                            [selected]="beneficiarySuffix.value === option.value"
                            [value]="option.value">
                              {{ option.displayName }}
                        </option>
                  </select>
              </div>
            </div>
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group mb-3">
                    <label class="col-form-label f-16" for="beneficiaryFormDateOfBirth">Date of Birth*</label>
                    <input 
                      type="text"
                      name="beneficiaryFormDateOfBirth"
                      id="beneficiaryFormDateOfBirth"
                      class="form-control"
                      (change)="checkValidation($event)"
                      [ngClass]="{'is-invalid':beneficiaryDateOfBirth.isValid}"
                      [(ngModel)]="beneficiaryDateOfBirth.value"
                      placeholder="mm/dd/yyyy"
                      aria-label="Date of Birth"
                      aria-describedby="basic-addon2"
                      bsDatepicker
                      [bsConfig]="datePickerConfig"
                      >
                      <div class="invalid-feedback">
                         Incorrect ages. User ages should be in between 18-100 years.
                      </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                  <label class="col-form-label f-16" for="beneficiaryFormContactNumber">Contact*</label>
                  <input 
                      type="text"
                      name="beneficiaryFormContactNumber"
                      id="beneficiaryFormContactNumber"
                      class="form-control"
                      [ngClass]="{'is-invalid':beneficiaryContactNumber.isValid}"
                      (focusin)="hideFormattedContactNumber('beneficiary')"
                      (focusout)="formatPhoneNumberData('beneficiary')"
                      (change)="commonService.checkAlphaAndDelete($event)"
                      [(ngModel)]="beneficiaryContactNumber.value"
                      (blur)="checkValidation($event)"
                      maxlength="10"
                      [placeholder]="'Eg. '+ contactNumberPlaceHolder"
                      aria-label="Contact Number"
                      aria-describedby="basic-addon1"
                    >
                  <div class="formatted-data" (click)="hideFormattedContactNumber('beneficiary')" *ngIf="beneficiaryShowFormatContactNumber">{{formattedBeneficiaryPhoneNumber}}</div>
                  <div class="invalid-feedback">
                    Contact number is required. It should be 10 digit, start with [2-9].
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                <label class="col-form-label f-16" for="beneficiaryFormSsn">SSN (Last 4 digit)</label>
                  <input 
                      type="text"
                      name="beneficiaryFormSsn"
                      id="beneficiaryFormSsn"
                      class="form-control"
                      [ngClass]="{'is-invalid': beneficiarySsn.isValid}"
                      (change)="checkValidation($event)"
                      placeholder="Eg. 1222"
                      aria-label="SSN number last 4 digit"
                      [(ngModel)]="beneficiarySsn.value"
                      aria-describedby="basic-addon1"
                      maxlength="4"
                    >
                    <div class="invalid-feedback">
                      Required field. Must have a number with 4 digit.
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="form-group mb-3">
                <label class="col-form-label f-16" for="beneficiaryFormDl">DL / ID</label>
                  <input 
                      type="text"
                      name="beneficiaryFormDl"
                      id="beneficiaryFormDl"
                      class="form-control"
                      (change)="checkValidation($event)"
                      placeholder="Eg. XXXXXX"
                      aria-label="Driver License"
                      [ngClass]="{'is-invalid': beneficiaryDriverLicense.isValid}"
                      [(ngModel)]="beneficiaryDriverLicense.value"
                      aria-describedby="basic-addon1"
                    >
                    <div class="invalid-feedback">
                      Required field.
                    </div>
                </div>
            </div>
          </div>
          <!-- beneficiary -->
          <div class="form-group p-l-10">
            <div class="checkbox p-0">
              <div class="checkbox p-0">
                <input 
                  type="checkbox"
                  id="beneficiaryFormSelfConsent"
                  name="beneficiaryFormSelfConsent" 
                  [checked]="true"
                  [readonly]="true"
                  />
                  <label class="text-muted f-16 p-l-20" for="beneficiaryFormSelfConsent">
                    Check to authorize
                  </label>
              </div>
            </div>
           </div>
        </div>
        <div *ngIf="commonService.expiredTokenError" class="text-danger">{{commonService.expiredTokenError}}</div>
        <div *ngIf="!commonService.expiredTokenError &&  apiProcess" class="api-process-loader">
          <div  class="loader-box">
              <div class="loader-9"></div>
          </div>
        </div>
        <div class="text-muted my-3 f-16">Note: To apply for the Affordable Connectivity Program, each section of this form must be completed, initial all agreement statements, and electronically sign the last page.</div>
        <div class="form-group mt-3 text-end">
          <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="submitPersonalInfoForm()">Next</button>
        </div>
      </div>
      </div>
      
