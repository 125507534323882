import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PersonalFormService } from 'src/app/shared/services/personal_form.services';
import { environment } from 'src/environments/environment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs'; 
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { FORM_CONST } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

interface IPrefix {
  displayName: string;
  value: string;
}

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('up', style({
        height: '0',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('down', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('up => down', animate('300ms ease-in')),
      transition('down => up', animate('300ms ease-out'))
    ])
  ]
})

export class PersonalComponent implements OnInit, AfterViewInit, OnDestroy { 
  
  public slideState = 'up'; 
  public slideStateConsent = 'up'; 

  protected companyId: string;
  private subscription: Subscription;
  private subscriptionRefresh: Subscription;
  public isRefreshToken: boolean = false
  public formSubmitted: boolean = false;
  public apiProcess:boolean = false;
  public errorMessage: string;
  public prefixData: IPrefix[];
  public beneficiaryPrefixData: IPrefix[];
  public errorCollection: string[];
  private timeOutId: number = -1;
  public personalShowFormatContactNumber: boolean = false;
  public beneficiaryShowFormatContactNumber: boolean = false;
  public showContactLabelInfo: boolean = false;

  public formattedPersonalPhoneNumber: string = "";
  public formattedBeneficiaryPhoneNumber: string = "";
  public contactNumberPlaceHolder: string = environment.HELPLINE_NUMBER;s


  @LocalStorage()
  protected zipCode;

  @LocalStorage()
  protected email;

  @LocalStorage()
  prefilledInfo;

  @LocalStorage()
  personalInfoData;

  @LocalStorage()
   stepperPrefilledData
  
  @Input()
  public filledData;

  formData =  { 
    personal: {
      firstName: {value: '', isValid: false},
      middleName: {value: '', isValid: false},
      lastName: {value: '', isValid: false},
      suffix: {value: ''},
      contactNumber: {value: '', isValid: false},
      selfConsent: { value: 'Yes', isValid: false},
    },
    beneficiaryForm: {
      isApplyingForSelf: { value: 'Yes' },
      selfConsentData: { value: 'Yes'},
      firstName: {value: '', isValid: false},
      middleName: {value: '', isValid: false},
      lastName: {value: '', isValid: false},
      suffix: {value: ''},
      dateOfBirth: {value: '', isValid: false},
      contactNumber: {value: '', isValid: false},
      ssn: {value: '', isValid: false},
      driverLicense: {value: '', isValid: false},
    }
  }

  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    private personalFormService: PersonalFormService, 
    private localStorage : LocalStorageService,
    private cookieService: CookieService,
    public commonService: CommonService,
    private router: Router
    ){

    this.commonService.setStepInfo('registration/personal');

    this.prefixData = [...environment.PREFIX_DATA]
    this.companyId = environment.COMPANY_ID;
    
    this.beneficiaryPrefixData = [...environment.PREFIX_DATA];

    const minYear:number = new Date().getFullYear() - 100;
    const maxYear:number = new Date().getFullYear() - 18 ;

    const minDate = new Date();
    minDate.setFullYear(minYear);

    const maxDate = new Date();
    maxDate.setFullYear(maxYear);
    

   this.datePickerConfig = Object.assign({}, { 
     containerClass: 'theme-dark-blue',
     showWeekNumbers: false,
     minDate,
     maxDate,
    });  
  } 

  ngOnInit(){
    if(this.personalInfoData){
      this.formData = this.personalInfoData;
    }
  }
 
    showContactLabel(){
      this.showContactLabelInfo = !this.showContactLabelInfo;
    }

    get personalFirstName(){
      return this.formData.personal.firstName;
    } 
    
    get personalLastName(){
      return this.formData.personal.lastName
    } 
    
    get personalMiddleName(){
      return this.formData.personal.middleName
    } 
    get personalSuffix(){
      return this.formData.personal.suffix
    }

    get personalSelfConsent(){
      return this.formData.personal.selfConsent;
    }

    get beneficiarySelfConsent(){
      return this.formData.beneficiaryForm.selfConsentData
    }

    get personalContactNumber(){
      return this.formData.personal.contactNumber
    }

    get isApplyingForSelf(){
      return this.formData.beneficiaryForm.isApplyingForSelf
    }

    get beneficiaryFirstName(){
      return this.formData.beneficiaryForm.firstName
    }
    get beneficiaryMiddleName(){
      return this.formData.beneficiaryForm.middleName
    }
    get beneficiaryLastName(){
      return this.formData.beneficiaryForm.lastName
    }
    get beneficiarySuffix(){
      return this.formData.beneficiaryForm.suffix
    }
    get beneficiaryDateOfBirth(){
      return this.formData.beneficiaryForm.dateOfBirth
    }
    get beneficiaryContactNumber(){
      return this.formData.beneficiaryForm.contactNumber
    }
    get beneficiarySsn(){
      return this.formData.beneficiaryForm.ssn
    }
    get beneficiaryDriverLicense(){
      return this.formData.beneficiaryForm.driverLicense
    }
    
 
  checkValidation(event){
    const {value, name }  = event.currentTarget;

    if(name === "personalFormFirstName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.formData.personal["firstName"].isValid = !testNumber;
    }

    else if(name === "personalFormMiddleName"){
      if(this.personalMiddleName.value){
        const testNumber = FORM_CONST.NAME_PATTERN.test(value);
        this.formData.personal["middleName"].isValid = !testNumber;
      }
      else  {
        this.formData.personal["middleName"].isValid = false; 
      }
    }
    else if(name === "personalFormLastName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.formData.personal["lastName"].isValid = !testNumber;
    }

    else if(name === "personalFormContactNumber"){
      const testNumber = FORM_CONST.PHONE_NUMBER_PATTERN.test(value);
      this.formData.personal["contactNumber"].isValid = !testNumber;
    }
    else if(this.isApplyingForSelf.value === 'Yes'){
      return;
    }
    //beneficiary form data
    
    else if(name === "beneficiaryFormFirstName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.formData.beneficiaryForm["firstName"].isValid = !testNumber;
    }

    else if(name === "beneficiaryFormMiddleName"){
      if(this.beneficiaryMiddleName.value){
        const testNumber = FORM_CONST.NAME_PATTERN.test(value);
        this.formData.beneficiaryForm["middleName"].isValid = !testNumber;
      }
      else {
        this.formData.beneficiaryForm["middleName"].isValid = false; 
      } 
    }
 
    else if(name === "beneficiaryFormLastName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.formData.beneficiaryForm["lastName"].isValid = !testNumber;
    }

    else if(name === "beneficiaryFormContactNumber"){
      const testNumber = FORM_CONST.PHONE_NUMBER_PATTERN.test(value);
      this.formData.beneficiaryForm["contactNumber"].isValid = !testNumber;
    }

    else if(name === "beneficiaryFormDob"){
      if(typeof this.beneficiaryDateOfBirth.value === 'object' || typeof this.beneficiaryDateOfBirth.value === 'string'){
        this.beneficiaryDateOfBirth.isValid = false;
      }
      else {
        this.beneficiaryDateOfBirth.isValid = true;
      }
    }

    else if(name === "beneficiaryFormSsn" || name === "beneficiaryFormDl"){
      if(this.beneficiarySsn.value){
        const value = parseInt(this.beneficiarySsn.value);

        if(typeof value !== 'number'){
          this.beneficiarySsn.isValid = true;
        }
        else {
          this.beneficiaryDriverLicense.isValid = false;
          this.beneficiarySsn.isValid = false;
        }
        
      }
      else if(this.beneficiaryDriverLicense.value){
        this.beneficiaryDriverLicense.isValid = false;
        this.beneficiarySsn.isValid = false;
      }
      else {
        this.beneficiarySsn.isValid = true;

        this.beneficiaryDriverLicense.isValid = true;
      }
    }
  }
  

  formatPhoneNumberData(formType){
    let contactNumber: number | string ;

    if(formType === 'personal'){
       contactNumber = parseInt(this.personalContactNumber.value);

       if(typeof contactNumber !== 'number'){
          return;
       }
    }
    if(formType === 'beneficiary'){
      contactNumber = parseInt(this.beneficiaryContactNumber.value);
      if(typeof contactNumber !== 'number'){
        return;
     }
    }
    contactNumber = contactNumber.toString();
   
    if(contactNumber.length === 10){
      const areaCode = contactNumber.substring(0, 3);
      const firstPart = contactNumber.substring(3, 6);
      const secondPart = contactNumber.substring(6, 10);
      const formattedPhoneNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
      if(formType === 'personal'){
        this.formattedPersonalPhoneNumber =  formattedPhoneNumber;       
        this.personalShowFormatContactNumber = true;
      }
      else if(formType === 'beneficiary'){
        this.formattedBeneficiaryPhoneNumber =  formattedPhoneNumber;
        this.beneficiaryShowFormatContactNumber = true; 
      }
    }
    else {
      if(formType === 'personal'){
        this.personalShowFormatContactNumber = false;
      }
      else if(formType === 'beneficiary'){
        this.beneficiaryShowFormatContactNumber = false; 
      }
    }
  }

  hideFormattedContactNumber(formType){
    if(formType === 'personal'){
      this.personalShowFormatContactNumber = false;
    }
    else if(formType === 'beneficiary'){
      this.beneficiaryShowFormatContactNumber = false; 
    }
  }

  formDataValidation(){
    const validArray = [];
    
    for(const [itemKey, itemValue] of Object.entries(this.formData.personal)){
      if(itemKey === 'suffix'){
        continue;
      }
      if(itemKey === 'contact'){
        continue;
      }
      if(itemKey === 'middleName'){
        continue;
      }
      
      if('isValid' in itemValue){
        if(!itemValue.value){
          this.formData.personal[itemKey].isValid = true; 
          validArray.push(true)
        }
        else {
          this.formData.personal[itemKey].isValid = false; 
          validArray.push(false)
        }
      }
    }
    if(this.isApplyingForSelf.value === 'No') {
      for(const [itemKey, itemValue] of Object.entries(this.formData.beneficiaryForm)){
        if(itemKey === 'suffix'){
          continue;
        }

        if(itemKey === 'middleName'){
          continue;
        }

        if(itemKey === "ssn" || itemKey === "driverLicense"){
          
          if(this.beneficiarySsn.value){
            const value = parseInt(this.beneficiarySsn.value);
              if(typeof value !== 'number'){
                this.beneficiarySsn.isValid = true;
                validArray.push(true);
              }
              else {
                this.beneficiaryDriverLicense.isValid = false;
                this.beneficiarySsn.isValid = false;
              }
          }
          else if(this.beneficiaryDriverLicense.value){
            this.beneficiaryDriverLicense.isValid = false;
            this.beneficiarySsn.isValid = false;
          }
          else if(!this.beneficiarySsn.value || !this.beneficiaryDriverLicense.value){
            this.beneficiaryDriverLicense.isValid = true;
            this.beneficiarySsn.isValid = true;
            validArray.push(true);
          }
        }

        else if('isValid' in itemValue){

          if(!itemValue.value){
            this.formData.beneficiaryForm[itemKey].isValid = true; 
            validArray.push(true)
          }
          else {
            this.formData.beneficiaryForm[itemKey].isValid = false; 
            validArray.push(false)
          }
        }
      }
    }
    if(validArray.includes(true)){
      return false;
    }
    else {
      return true
    }
  }

  submitPersonalInfoForm(){
    const data = this.formDataValidation();
    
    if(!this.formDataValidation()){
      return;
    }
      this.timeOutId = window.setTimeout( () => {
        this.apiProcess = true;
      }, 1200);
      this.errorMessage = '';
      this.errorCollection = [];

      const userID = +this.cookieService.get('x-userId');
      
      const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        zipCode: this.zipCode,
        email: this.email,
        source: "WebBrowser",
        enrolmentType: "Enroll",
        isApplyingForSelf: this.isApplyingForSelf.value,
        personal: {
          firstName: this.personalFirstName.value,
          middleName: this.personalMiddleName.value,
          lastName: this.personalLastName.value,
          suffix: this.personalSuffix.value,
          contactNumber: this.personalContactNumber.value,
          selfConsent: this.personalSelfConsent.value
        }
      }

      if(this.isApplyingForSelf.value === 'No'){
        const dateOfBirth = new Date(this.beneficiaryDateOfBirth.value);
        const dd = dateOfBirth.getDate().toString();
        const mm = dateOfBirth.getMonth() + 1;
        const yyyy = dateOfBirth.getFullYear().toString();
        
        const dob = {
          mm : `${mm}`.length === 1 ? `0${mm}` : mm,
          dd: dd.length === 1 ? `0${dd}` : dd,
          yyyy
        }
  
        payload["beneficiary"] = {
          firstName: this.beneficiaryFirstName.value,
          middleName: this.beneficiaryMiddleName.value,
          lastName: this.beneficiaryLastName.value,
          suffix: this.beneficiarySuffix.value,
          dateOfBirth: `${dob.mm}-${dob.dd}-${dob.yyyy}`,
          contactNumber: this.beneficiaryContactNumber.value,
          ssn: this.beneficiarySsn.value,
          driverLicense: this.beneficiaryDriverLicense.value,
          selfConsent: 'Yes'
        }
      }
    
      this.subscription =  this.personalFormService.post(payload).subscribe(
        (response) => {
          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.apiProcess = false;
          if(response.status === 200 && response.body.Status){
            if(response.body.data.enrollmentNumber){
              this.commonService.updateEnrollment(response.body.data.enrollmentNumber);
            }

            this.formSubmitted = true;
            this.localStorage.store('personalInfoData', this.formData);
            
            const nextRoute = this.commonService.setRouter(response.body.data.currentStep);
            this.router.navigate([nextRoute]);
         }
          else {
            const {body} = response;
            if('error' in body && typeof body["error"] === 'object'){
              const keys = Object.keys(body.error);
              
              // keys.map( (fieldKey:string) => {
              //   if(fieldKey === 'firstName'){
              //     this.firstName.setErrors({customError: body["error"].firstName});
              //   }
              //   else if(fieldKey === 'middleName'){
              //     this.middleName.setErrors({customError: body["error"].middleName});
              //   }
              //   else if(fieldKey === 'lastName'){
              //     this.lastName.setErrors({customError: body["error"].lastName});
              //   }
              //   else if(fieldKey === 'contactNumber'){
              //     this.contactNumber.setErrors({customError: body["error"].contactNumber});
              //   }
              //   else if(fieldKey === 'suffix'){
              //     this.suffix.setErrors({customError: body["error"].suffix});
              //   }
              // })
            }
                
            this.errorMessage = response.body.message;
            this.formSubmitted = false;
            const {error} = response.body;
            if(typeof error === 'string'){
              this.errorMessage = error;
            }
            const result = this.commonService.getErrorMessageArranged(error)
            if(result instanceof Array){
              this.errorCollection = [...result];
            }
          }
        },
        (error) => {
          window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.formSubmitted = false
          this.commonService.expiredTokenError = "";
          if(!error.error ){
            this.errorMessage = "Unknown error";
          }
          else {
            this.errorMessage = error.error.message;
          }
        }
      );
  }
 

 

  ngAfterViewInit(){
    
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionRefresh){
      this.subscriptionRefresh.unsubscribe();
    }
  }
}
