import * as dayjs from 'dayjs'; 
import { AbstractControl } from '@angular/forms';

export function userAgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  // Custom validation logic
  const {value} = control;
    const userDate = dayjs(value);
    const sub = dayjs().subtract(userDate.get('year'), 'year');
    if(sub.get('year') >= 18 && sub.get('year') <= 100){
        return null;
    } 
    return {ageError: true};
}
