import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceAvailability } from 'src/app/shared/services/network.services';
@Component({
  selector: 'app-continue-resume.',
  templateUrl: './continue-resume.component.html',
  styleUrls: ['./continue-resume.component.scss'],
})

export class ContinueResumeComponent implements OnInit, OnDestroy {
  resumeUUID: string;

  constructor(private activatedRoute: ActivatedRoute,
    private route: Router, private networkServices: ServiceAvailability) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.resumeUUID = params.get('id');
      this.getUserDetail();
    });
  }

  getUserDetail() {
    this.networkServices.getUserDetails(this.resumeUUID).subscribe((res: any) => {
      if (res && res.Status) {
        localStorage.setItem('resume', JSON.stringify(res.data));
        this.route.navigate(['network-strength']);
      }
    })
  }

  ngOnDestroy(): void {
  }

}
