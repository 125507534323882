import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAllPlanDevices } from 'src/app/shared/model/plan_device.model';
import { PlanServices } from 'src/app/shared/services/plan_device.services';
import { RefreshServices } from 'src/app/shared/services/refresh.services';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit, OnDestroy {
  
  public planData: IAllPlanDevices; 
  showDevices: boolean;

  public allPlans; 
  public helpline = environment.HELPLINE_NUMBER;

  @LocalStorage()
  private stepsInfo;

  constructor(
    public planService: PlanServices,
    public tokenService: RefreshServices,
    private cookieService: CookieService, 
    public commonService: CommonService,
    private router: Router
  ){
    this.commonService.setStepInfo('registration/plan');

    if(this.planData){
      this.showDevices = this.planData.showDevices ? this.planData.showDevices : false;
    }
  }

  ngOnInit(){
    const userID = +this.cookieService.get('x-userId');
    this.planService.getPlanReq({userID, companyId: environment.COMPANY_ID}).subscribe(
      response => {
        
        this.allPlans = response
      },
      error =>  {

      }
    )
  }

  next(){}

  previous(){
    const stepData = this.stepsInfo.find( item => item.Step === 11)
    const nextRoute = this.commonService.setRouter(stepData.PrevStep);
    this.router.navigate([nextRoute]);
  }

  retryAgain(){
    this.router.navigate(['registration', 'plan']);
  }
  
  checkEnableCarousel(){
    return this.planService.showPlanCarousel;
  }

   ngOnDestroy(){}

}
