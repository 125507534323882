import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable,  catchError, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable()
export class HttpHeaderInterceptorService implements HttpInterceptor {

  

  constructor(
    private cookieService: CookieService,
    private router: Router,
    ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('x-token');
    if(req.url.endsWith('/authenticate')) {
      return next.handle(req);
    }
    
    if(req.url === environment.AUTH.GET_IP){
      return next.handle(req);
    }

    const headers = new HttpHeaders({
      'authorization': `Bearer ${token}`
    })

    const modifiedReq = req.clone({ 
      headers
    });

    // Send the modified request
    return next.handle(modifiedReq).pipe(
      catchError(errordata => {
        if (errordata.status === 401) {
            window.location.href = "/";
        }
        return throwError(errordata);
      })
    )
   }

    addTokenHeader(request: HttpRequest<any>, token: any) {
      return request.clone({ headers: request.headers.set('Authorization', 'bearer ' + token) });
    }    
  }
