import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from "rxjs";
import { CommonService } from "src/app/shared/services/common.service";
import { LocalStorageService } from "ngx-webstorage";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {

  // public newUser = false;
  // public show: boolean = true
  public errorMessage: string;
  public companyId: string;
  public cookieValue: string
  public invalidCredential: boolean = false;
  public apiProcess:boolean = true; 
  public redirectUrl:string = ''; 
  private timeOutId: number = -1;
  
  // cookies expiration setting logic
  // private expires: Date = new Date(); 
  private subscription: Subscription;
  // private interValSubscription: Subscription;
  
  // private intervalObserver = interval(1500);

  constructor(
    private router: Router, 
    private commonServices: CommonService,
    private cookieService: CookieService,
    private localStorage: LocalStorageService

    ) {
    this.companyId = environment.COMPANY_ID;
    // this.loginForm = this.fb.group({
    //   userName: ["cluster3234", Validators.required],
    //   password: ["pravendra", Validators.required],
    // });
  }

  ngOnInit() {
   
  }

}
