 <!-- error-503 start-->
 <div class="error-wrapper">
    <div class="container"><img class="img-100" src="assets/images/other-images/sad.png" alt="">
      <div class="error-heading">
        <h2 class="headline font-secondary">Ah!!</h2>
      </div>
      <div class="col-md-8 offset-md-2">
        <p class="sub-content">
          Please check your internet connection or The page you are attempting to reach is currently not available.
          For support please call <a href="tel:{{contactNumber}}">{{contactNumberPlaceHolder}}</a>
        </p>
      </div>
      <div><button class="btn btn-secondary-gradien btn-lg" (click)="refresh()">RETRY</button></div>
      
    </div>
  </div>
  <!-- error-503 end-->