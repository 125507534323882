import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { LocalStorage } from 'ngx-webstorage';
import { IPersonalReq } from "../model/personal_info.model";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PersonalFormService {
  protected baseApi: string;
  protected token: string; 

    @LocalStorage()
    protected uuid;

    @LocalStorage()
    protected xExpire;

    constructor(
        private http:HttpClient
        ) {
        this.baseApi = environment.BASE_API;
      }

    // put(req: IPersonalReq):Observable<HttpResponse<any>> {
    //   return this.http.put(`${this.baseApi}/${environment.AUTH.PERSONAL}/${this.uuid}`, req, {
    //     observe: 'response'});
    // }

    post(req: IPersonalReq):Observable<HttpResponse<any>> {
      return this.http.post(`${this.baseApi}/${environment.AUTH.PERSONAL}/${this.uuid}`, req, {
        observe: 'response'});
    }
  }