import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error503',
  templateUrl: './error503.component.html',
  styleUrls: ['./error503.component.scss']
})

export class Error503Component implements OnInit {
  
  public contactNumberPlaceHolder: string = environment.HELPLINE_NUMBER;
  public contactNumber: string = environment.CONTACT_NUMBER;

  constructor(
    private router: Router
  ) { }


  ngOnInit(): void {
  }


  refresh(){
    window.location.href = "/"
  }

}
