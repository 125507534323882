<div
    id="{{program.shortName}}"
    [ngClass]="{
      'text-danger': programService.nslInvalidCard && programService.checkSelected(program.shortName),
      'active': !programService.nslInvalidCard && programService.checkSelected(program.shortName)
      }"
    class="list-group-item list-group-item-action js-check-card d-flex justify-content-between align-items-center">
    <h5 class="m-r-10">{{program.name}}</h5> 
    <div>
      <button 
        class="btn btn-pill btn-primary"
        *ngIf="!(programService.checkSelected(program.shortName)); else chooseBtn"
        (click)="programService.renderAdditionalForm(program, $event, inputFields)">
        Choose
    </button>
    <ng-template #chooseBtn>
      <button 
        class="btn btn-pill btn-secondary"
        (click)="programService.removeNslCheckSelected(program.shortName)">
          Remove
      </button>
      <button
        class="btn btn-pill btn-danger m-l-10"
        (click)="commonService.open(inputFields, 'lg')"
        >
          Edit
      </button>
    </ng-template>
  </div>
</div>
  <ng-template #inputFields let-modal>
    <div class="modal-body">
      <div class="close-modal close-modal-nsl p-absolute" (click)="modal.close()">
        <i class="icon-close"></i>
      </div>
       <div class="theme-form p-20">
          <h5 class="m-b-3">Please fill the details</h5>
          <div class="row">
             <ng-container *ngFor="let nslItem of NSL">

                <div class="col-12 col-sm-6" *ngIf="nslItem.displayName.shortName !== 'dob'">
                   <div class="form-group mb-3">
                      <label class="col-form-label f-16">
                      {{nslItem["displayName"].label}} <span *ngIf="nslItem.displayName.shortName !== 'middleName'">*</span>
                      </label>
                      <ng-container *ngIf="nslItem.displayName.label === 'Suffix'; then selectElement else inputElement"></ng-container>
                      <ng-template #selectElement>
                         <select
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         placeholder="Eg. John2"
                         aria-label="Suffix"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         (change)="programService.checkNSLValidation(nslItem, $event)"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         >
                         <option 
                           class="p-10"
                           [selected]="option.value === nslItem.displayName.value"
                           *ngFor="let option of programService.prefixData"
                           [value]="option.value">
                           {{ option.displayName }}
                         </option>
                         </select>
                         <div class="invalid-feedback">
                           Required field.
                        </div>
                      </ng-template>
                      <ng-template #inputElement>
                        <ng-container *ngIf="nslItem.displayName.shortName!=='middleName'; else middleName">
                         <input 
                         type="text"
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         [placeholder]="'Eg. '+ nslItem.displayName.label"
                         [ariaLabel]="nslItem.displayName.label"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         [maxlength]="nslItem.displayName.shortName==='ssn' && 4"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         (blur)="programService.checkNSLValidation(nslItem, $event)"
                         >
                         <div *ngIf="nslItem.displayName.shortName !=='ssn'; else ssnError" class="invalid-feedback">
                            Required field.
                         </div>
                         <ng-template  #ssnError>
                           <div class="invalid-feedback">
                              Required field, must be a number type data.
                           </div>
                         </ng-template>
                      </ng-container>
                      <ng-template #middleName>
                        <input 
                         type="text"
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         [placeholder]="'Eg. '+ nslItem.displayName.label"
                         [ariaLabel]="nslItem.displayName.label"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         (blur)="programService.checkNSLValidation(nslItem, $event)"
                         >
                      </ng-template>
                     </ng-template>
                   </div>
                </div>
                <div *ngIf="nslItem.displayName.shortName === 'dob'" class="col-12 col-sm-6" >
                   <div class="form-group mb-3">
                      <label class="col-form-label f-16">Date of Birth*</label>
                      <input 
                      type="text"
                      [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                      class="form-control"
                      [disabled]="nslItem.displayName.editable === 'N'"
                      placeholder="Eg. mm/dd/yyyy"
                      (change)="programService.checkNSLValidation(nslItem, $event)"
                      aria-label="Date of Birth"
                      [ngClass]="{'is-invalid': nslItem.isValid}"
                      aria-describedby="basic-addon1"
                      [(ngModel)]="nslItem.displayName.value"
                      [bsConfig]="programService.datePickerConfig"
                      bsDatepicker
                      >
                      <div class="invalid-feedback">
                         Incorrect ages. Ages should be in between 4-22 years.
                      </div>
                   </div>
                </div>
             </ng-container>
          </div>
          <div class="form-group mb-3 text-end">
            <button type="submit" class="btn btn-light" (click)="programService.resetNSLForm()">Reset</button>
            <button type="button"  class="btn btn-primary m-l-10" (click)="programService.saveNSLForm('save and close')">Save and Close</button>
         </div>     
       </div>
    </div>
  </ng-template>