import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

// Components

import { SwiperModule } from "swiper/angular";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";


@NgModule({
  declarations: [
    FeatherIconsComponent,
    TapToTopComponent,
  ],
  imports: [
    CommonModule, 
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forRoot(),
    SwiperModule],
  providers: [],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FeatherIconsComponent,
    TapToTopComponent,
  ],
})

export class SharedModule {}
