<div *ngIf="apiProcess" class="api-process-loader">
  <div  class="loader-box">
      <div class="loader-9"></div>
  </div>
</div>
<ng-container *ngIf="errorMessage">
  <app-error503></app-error503>
</ng-container>

<!-- <div class="container-fuild p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4 class="font-primary text-center">Sign in to account</h4>
              <p class="text-center">Enter your email & password to login</p>
               <div class="form-group">
                <label class="col-form-label f-16">Username</label>
                <input
                  class="form-control"
                  [type]="'text'"
                  required=""
                  placeholder="username"
                  formControlName="userName" />
              </div>
              <div class="form-group">
                <label class="col-form-label f-16">Password</label>
                <input 
                class="form-control"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required=""
                  placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="remember1" type="checkbox" />
                  <label class="text-muted" for="remember1">Remember password</label>
                </div>
                <div *ngIf="invalidCredential" class="error-invalid f-14">
                  {{'Error: '+errorMessage}}
                </div> -->
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                <!-- <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid || apiProcess" (click)="login()" type="button"><span>Sign in</span></button>
                </div>
                
              </div> -->
              <!-- <p class="mt-4 mb-0">
                Don't have account?
                <a [routerLink]="'/register'" class="ms-2">Create Account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
</div> -->

