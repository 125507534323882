import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-list-school-lunch',
  templateUrl: './list-school-lunch.component.html',
  styleUrls: ['./../list-program.component.scss']
})

export class ListSchoolLunchComponent {

  @Input()
  public program;

  @Input()
  public index;


  public NSL: any[];

  constructor(
    public programService: ProgramServices,
    public commonService: CommonService
  ){}

  toggleClass(programName):boolean {
    const dataSelected = this.programService.checkSelected(programName);
    return this.programService.nslInvalidCard && dataSelected;
  }

  ngOnInit(){
    this.NSL = this.program.inputFields;
    this.NSL.forEach(element => {
      if(element.required === 'Y'){
        element["isValid"] = false
      }
    });
  }
}

