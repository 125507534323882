<div class="header-wrapper">
    <div class="announcement">
        <div class="announcement__wrapper">
            <a href="https://apply.gounitywireless.com/?_gl=1*1xcbrkg*_ga*MjA4MjgxNzYzMi4xNzA1Njc2NTM5*_ga_HFTEMMRTNS*MTcwNjUyODg1MC4zLjEuMTcwNjUyOTM2Ny42MC4wLjA." class="announcement__link"><div class="announcement__text" data-text="free-phone-service-qualify-today">
            free phone service. qualify today!
          </div>
        </a>
        </div>
    </div>

<header class="site-header" data-overlay="false" #headerRef>
    <div class="page-width"><div class="site-nav__thumb-menu site-nav__thumb-menu--inactive">
      <button type="button" class="btn site-nav__thumb-button js-toggle-slide-nav" (click)="toggleMegaMenu()">
        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-hamburger" viewBox="0 0 64 64"><path d="M7 15h51M7 32h43M7 49h51"></path></svg>
        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" viewBox="0 0 64 64"><path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path></svg>
      </button>
      <!-- <a href="/cart" class="site-nav__thumb-cart js-drawer-open-cart js-no-transition" aria-controls="CartDrawer" data-icon="bag">
        <span class="cart-link"><svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-bag" viewBox="0 0 64 64"><g fill="none" stroke="#000" stroke-width="2"><path d="M25 26c0-15.79 3.57-20 8-20s8 4.21 8 20"></path><path d="M14.74 18h36.51l3.59 36.73h-43.7z"></path></g></svg><span class="icon__fallback-text">Cart</span>
          <span class="cart-link__bubble">
            <span class="cart-link__bubble-num" aria-hidden="true">
              0
            </span>
          </span>
        </span>
      </a>-->
    </div></div>

      <div class="header-layout header-layout--left-center header-layout--mobile-logo-only" data-logo-align="left"><div class="header-item header-item--logo"><style data-shopify="">.header-item--logo,
  .header-layout--left-center .header-item--logo,
  .header-layout--left-center .header-item--icons {
    -webkit-box-flex: 0 1 80px;
    -ms-flex: 0 1 80px;
    flex: 0 1 80px;
  }

  @media only screen and (min-width: 769px) {
    .header-item--logo,
    .header-layout--left-center .header-item--logo,
    .header-layout--left-center .header-item--icons {
      -webkit-box-flex: 0 0 170px;
      -ms-flex: 0 0 170px;
      flex: 0 0 170px;
    }
  }

  .site-header__logo a {
    max-width: 80px;
  }
  .is-light .site-header__logo .logo--inverted {
    max-width: 80px;
  }

  @media only screen and (min-width: 769px) {
    .site-header__logo a {
      max-width: 170px;
    }

    .is-light .site-header__logo .logo--inverted {
      max-width: 170px;
    }

    
    .site-header--sticky .site-header__logo a {
      max-width: 127.5px;
    }
  }</style><div class="h1 site-header__logo" itemscope="" itemtype="http://schema.org/Organization"><h1 class="visually-hidden">Unity Wireless</h1>
  <a href="/" itemprop="url" class="site-header__logo-link">
    <img class="small--hide" src="//unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_170x.png?v=1656791045" srcset="//unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_170x.png?v=1656791045 1x, //unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_170x@2x.png?v=1656791045 2x" alt="Unity Wireless" itemprop="logo">
    <img class="medium-up--hide" src="//unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_80x.png?v=1656791045" srcset="//unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_80x.png?v=1656791045 1x, //unitywireless.com/cdn/shop/files/1_71813cf9-1689-4e84-a1c6-7b3a0cd8830a_80x@2x.png?v=1656791045 2x" alt="Unity Wireless">
  </a></div><div class="numb"><p style=""><span class="dott">.</span> Customer Care</p>
<p>(954) 613-6051 </p></div>     
          </div><div role="navigation" aria-label="Primary" class="header-item header-item--navigation text-center small--hide"><ul class="site-nav site-navigation small--hide"><li class="site-nav__item site-nav__expanded-item">

    <a href="/" class="site-nav__link">
      Home
    </a></li><li class="site-nav__item site-nav__expanded-item site-nav--has-dropdown" aria-haspopup="true">

    <a href="/" class="site-nav__link site-nav__link--has-dropdown">
      Shop
    </a><ul class="site-nav__dropdown text-left"><li class="">
            <a href="https://Phones.Unitywireless.com" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Phones
</a></li><li class="">
            <a href="https://apply.gounitywireless.com/ACP/Login.php" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Top-ups
</a></li></ul></li><li class="site-nav__item site-nav__expanded-item site-nav--has-dropdown" aria-haspopup="true">

    <a href="/pages/acp-free-service" class="site-nav__link site-nav__link--has-dropdown">
      About ACP
    </a><ul class="site-nav__dropdown text-left"><li class="">
            <a href="/pages/schools-acp-free-service" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Schools
</a></li></ul></li><li class="site-nav__item site-nav__expanded-item site-nav--has-dropdown" aria-haspopup="true">

    <a href="/pages/contact" class="site-nav__link site-nav__link--has-dropdown">
      Support 
    </a><ul class="site-nav__dropdown text-left"><li class="">
            <a href="/pages/how-to-qualify" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              How To Qualify
</a></li><li class="">
            <a href="/pages/apn-settings" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              APN Settings
</a></li><li class="">
            <a href="https://apply.gounitywireless.com/ACP/BYOD.php" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              BYOD Checker
</a></li><li class="">
            <a href="/pages/about-us" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              About Us
</a></li><li class="">
            <a href="/pages/contact" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Contact
</a></li></ul></li><li class="site-nav__item site-nav__expanded-item site-nav--has-dropdown" aria-haspopup="true">

    <a href="https://apply.gounitywireless.com/ACP/Login.php" class="site-nav__link site-nav__link--has-dropdown">
      My Account
    </a><ul class="site-nav__dropdown text-left"><li class="">
            <a href="https://apply.gounitywireless.com/ACP/Login.php" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Login 
</a></li><li class="">
            <a href="https://apply.gounitywireless.com/ACP/Signup.php" class="site-nav__dropdown-link site-nav__dropdown-link--second-level">
              Signup
</a></li></ul></li></ul>
<div class="numb"><p style=""><span class="dott">.</span> Customer Care</p>
<p>(954) 613-6051 </p></div>     
          </div>
        <div class="header-item header-item--icons small--hide"><div class="site-nav site-nav--icons">
<div class="site-nav__icons"><button type="button" class="site-nav__link site-nav__link--icon js-toggle-slide-nav medium-up--hide">
      <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-hamburger" viewBox="0 0 64 64"><path d="M7 15h51M7 32h43M7 49h51"></path></svg>
      <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" viewBox="0 0 64 64"><path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path></svg>
      <span class="icon__fallback-text">Menu</span>
    </button><a href="/cart" class="site-nav__link site-nav__link--icon site-nav__link--cart js-drawer-open-cart js-no-transition" aria-controls="CartDrawer" data-icon="bag">
      <!-- <span class="cart-link"><svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-bag" viewBox="0 0 64 64"><g fill="none" stroke="#000" stroke-width="2"><path d="M25 26c0-15.79 3.57-20 8-20s8 4.21 8 20"></path><path d="M14.74 18h36.51l3.59 36.73h-43.7z"></path></g></svg><span class="icon__fallback-text">Cart</span>
        <span class="cart-link__bubble">
          <span class="cart-link__bubble-num" aria-hidden="true">
            0
          </span>
        </span>
      </span> -->
    </a></div>
</div>
<div class="numb"><p style=""><span class="dott">.</span> Customer Care</p>
<p>(954) 613-6051 </p></div>     
        </div>
      </div></header>
<!--       

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#">Navbar</a>
  
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Disabled</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form>
    </div>
</nav> -->
</div> 

<ng-container>
</ng-container>
