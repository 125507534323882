<div
  id="{{program.shortName}}"
  [ngClass]="{
    'text-danger': programService.fphInvalidCard && programService.checkSelected(program.shortName), 
    'active': !programService.fphInvalidCard && programService.checkSelected(program.shortName)
    }"
  class="list-group-item list-group-item-action js-check-card d-flex justify-content-between align-items-center"
  >
  <h5 class="m-r-10">{{program.name}}</h5> 
  <div>
    <button 
      *ngIf="(!programService.fphInvalidCard && programService.fphSelected === ''); else chooseBtn"
      class="btn btn-pill btn-primary"
      (click)="programService.renderAdditionalForm(program, $event, federalPrograms)">
      Choose
    </button>
    <ng-template #chooseBtn>
      <button 
        class="btn btn-pill btn-secondary"
        (click)="programService.removeFphCheckSelected(program.shortName)">
          Remove
      </button>
     <button class="btn btn-pill btn-danger m-l-10"
      (click)="commonService.open(federalPrograms, 'lg')"
      >
        Edit
    </button>
    </ng-template>
  </div>
</div>
<ng-template #federalPrograms let-modal>
  <div class="modal-body">
    <div class="close-modal p-absolute" (click)="programService.closeFPHModal('close icon click')">
      <i class="icon-close"></i>
    </div>
     <div class="theme-form p-20">
      <h5 class="m-b-3">Please choose the availed program</h5>
      <div class="row p-t-20">
        <ng-container *ngFor="let fdProgram of FPH">
          <div class="col-sm-6">
            <div class="card min-h-120 cursor-pointer"
              (click)="programService.fphOnChange(fdProgram.shortName)"
              [ngClass]="{
                'b-light': programService.fphSelected !== fdProgram.shortName,
                'b-success': programService.fphSelected === fdProgram.shortName,
              }"
            >
              <div class="media p-20">
                <div class="form-check radio radio-primary me-3">
                  <input
                    class="form-check-input"
                    [id]="'fph'+fdProgram.id"
                    type="radio"
                    name="radio1"
                    [defaultChecked]="fdProgram.shortName === programService.fphSelected"
                    [value]="fdProgram.shortName"
                  />
                  <label 
                  class="form-check-label f-16" 
                  [for]="'fph'+fdProgram.id"></label>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mega-title-badge">
                    {{fdProgram.name}}
                    <span class="badge badge-primary pull-right digits"
                      >{{fdProgram.shortName +' | ' + fdProgram.nladCode}}</span
                    >
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="font-danger"
          *ngIf="programService.fphInvalidCard">
          No radio selection. Please select the radio option
        </div>
      </div>
     </div>
     <div class="form-group mt-0 text-end">
      <button type="button"  class="btn btn-light" (click)="programService.resetFPHForm()">Reset</button>
      <button type="button"  class="btn btn-primary m-l-10" (click)="programService.saveFPHForm('save and close')">Save and Close</button>
   </div>      
  </div>
</ng-template>

