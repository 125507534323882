<ng-container *ngIf="device.comboType.toLowerCase() === 'tablet' && responseModalData.eligibleForDevice === 'N'; else otherDevices">
  <div class="device-not-eligible-icon">
    <app-feather-icons [icon]="'slash'"></app-feather-icons>
  </div>
  <div 
  class="card card-disable border-2 b-light p-relative"
  title="You are not eligible for tablet"
  >
   <img *ngIf="device.imagePath" class="card-img-top border-top-left-radius" src="{{device.imagePath}}" alt="{{device.name}}">
   <div class="p-absolute badge-position-right">
    <span class="badge badge-primary tag-pills-sm-mb">
      ${{device.comboPrice}} <ng-container *ngIf="device.discountPrice">Discount <span class="strike">${{device.discountPrice}}</span></ng-container>
    </span>
  </div>
  <div 
  class="card-body">
    
    <h5 class="card-title">{{device.comboTitle}}</h5>
    <h6 class="card-title mt-3">{{device.comboDesc}}</h6>
    <ul *ngIf="device.products.length > 0" class="list-group list-group-flush">
      {{device.banners | json }}
    <ng-container *ngFor="let banner of device.banners">
        <li class="list-group-item">
          <i class="fa fa-check-square-o font-success"></i>  
          {{banner.bannerDesc}}
        </li>
    </ng-container>
  </ul>
  </div>
</div>
</ng-container>
<ng-template #otherDevices>
<div 
  class="card cursor-pointer p-relative border-2"
  [ngClass]="{
    'b-success': deviceService.checkSelectedDevices(device.comboId),
    'b-light': !deviceService.checkSelectedDevices(device.comboId),
    }"

  (click)="makeSelectionDevice(device)"
>
<div class="p-absolute badge-position-right">
  <span class="badge badge-primary tag-pills-sm-mb">
    ${{device.comboPrice}} <ng-container *ngIf="device.discountPrice">Discount <span class="strike">${{device.discountPrice}}</span></ng-container>
  </span>
</div>
 <img *ngIf="device.imagePath" class="card-img-top border-top-left-radius" src="{{device.imagePath}}" alt="{{device.name}}">
 <!-- <img  class="card-img-top border-top-left-radius" src="./../../../../../../assets/images/banner/3.jpg" alt="{{device.name}}"> -->
  <div 
  class="card-body">
    
    <h5 class="card-title">{{device.comboTitle}}</h5>
    <h6 class="card-title mt-3">{{device.comboDesc}}</h6>
    <ul *ngIf="device.products.length > 0" class="list-group list-group-flush">
      <ng-container *ngFor="let banner of device.banners">
        <li class="list-group-item">
          <i class="fa fa-check-square-o font-success"></i>  
          {{banner.bannerDesc}}
        </li>
    </ng-container>
  </ul>
  </div>
</div>
</ng-template>