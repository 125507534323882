<div class="login-main program-form custom-p-20 p-relative">
   <div class="form-group mt-3 p-absolute btn-top-action text-end">
      <button class="btn btn-light btn-lg mx-3" type="button" (click)="previous()">Back</button>
      <button class="btn btn-primary btn-lg" type="button" (click)="saveSelectedDevice()">Next</button>
   </div>

<ng-container *ngIf="deviceService.loadDevices; else loaderDevice">
   <div *ngIf="apiProcess" class="api-process-loader">
      <div  class="loader-box">
          <div class="loader-9"></div>
      </div>
  </div>
   <h4 class="font-primary">Select Your Device</h4>
   <div class="product-wrapper-grid listView m-t-20">
   <div class="row">
      <ng-container *ngFor="let device of deviceService.dataPlanDevice.allDevices; index as planIndex">
         <div class="col-12 col-sm-6 col-md-6 col-lg-3 d-flex align-items-stretch">
            <app-sub-device (toggleApiProcess)="updateApiProcess(device)" [responseModalData]="responseModalData" [device]="device"></app-sub-device>
         </div>
      </ng-container>
      <div class="text-danger f-12" *ngIf="errorMessage">{{errorMessage}}</div>
      <div class="form-group text-end">
         <button class="btn btn-light btn-lg mx-3" type="button" (click)="previous()">Back</button>
         <button class="btn btn-primary btn-lg" type="button" (click)="saveSelectedDevice()">Next</button>
      </div>
   </div>
</div>
</ng-container>
<ng-template #loaderDevice>
   <div class="loader-box">
      <div class="loader-9"></div>
  </div>
</ng-template>
</div>

<ng-template #devicePromoModal let-modal>
   <div class="modal-body">
     <div class="close-modal p-absolute" (click)="modal.close()">
       <i class="icon-close"></i>
     </div>
     <div class="theme-form p-20"> 
           <div class="text-center">
               <div class="success-icon text-center mb-3">
                  <app-feather-icons [icon]="'check-square'"></app-feather-icons>
               </div>
               <p class="f-20 m-b-20 text-center">You are eligible for the tablet.</p>
          </div> 
           <div class="form-group p-t-20 mt-3 text-center">
               <button type="button" class="btn btn-primary" (click)="modal.close()">Continue</button>
           </div>
     </div>
   </div>
</ng-template>