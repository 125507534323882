import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAllPlanDevices } from 'src/app/shared/model/plan_device.model';
import { PlanServices } from 'src/app/shared/services/plan_device.services';

@Component({
  selector: 'app-sub-device',
  templateUrl: './sub-device.component.html',
  styleUrls: ['./sub-device.component.scss']
})
export class SubDeviceComponent {
  
  @Input()
  public device;
  
  @Input()
  public responseModalData;


  public subDeviceData: IAllPlanDevices; 
  private subscription: Subscription;
  
  errorMessage;

  @Output() toggleApiProcess = new EventEmitter();

  constructor(
    public deviceService: PlanServices,
  ){}
  
   makeSelectionDevice(device){
    this.toggleApiProcess.emit(device);
    this.deviceService.selectDevice(device.comboId, device.comboPrice, device.unlocksOtherCombos, device.allowMultiOrder);
   }
}
