import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FORM_CONST } from 'src/app/shared/constants';
import { userAgeValidator } from 'src/app/shared/validators/ageMatchValidator';
import { SecurityFormService } from 'src/app/shared/services/securit_form.services';
import { environment } from 'src/environments/environment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription, tap } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})

export class SecurityComponent  implements OnInit, OnDestroy  {
    public securityForm: FormGroup;

    public formSubmitted: boolean = false;
    public apiProcess:boolean = false;
    public errorMessage: string;
    public showDlInput: boolean = false;
    private subscription: Subscription;
    private subscriptionRefresh: Subscription;
    private subscriptionSecurity: Subscription;

    private subscriptionInput1: Subscription;
    private subscriptionInput2: Subscription;

    private timeOutId: number = -1;

    @LocalStorage()
    private prefilledInfo;

    @LocalStorage()
    private stepsInfo;
    
    @LocalStorage()
    private dlStoreData

    @LocalStorage()
    private dlStoreDataEndpoint

    @Input()
    stepType: string;


    @Input()
    public filledData;

    datePickerConfig: Partial<BsDatepickerConfig>;


    constructor(
      private fb: FormBuilder,
      private securityServices: SecurityFormService,
      private localStorage: LocalStorageService,
      public commonService: CommonService,
      private cookieService: CookieService,
      private router: Router
    ){
      this.commonService.setStepInfo('registration/security');
      const minYear:number = new Date().getFullYear() - 100;
      const maxYear:number = new Date().getFullYear() - 18 ;
 
      const minDate = new Date();
      minDate.setFullYear(minYear);
 
      const maxDate = new Date();
      maxDate.setFullYear(maxYear);
 
     this.datePickerConfig = Object.assign({}, { 
       containerClass: 'theme-dark-blue',
       showWeekNumbers: false,
       minDate,
       maxDate,
      }); 
    }

    ngOnInit(){

      this.securityForm =  this.fb.group({
        dateOfBirth: [
          '', 
          [
            Validators.required,
            userAgeValidator
          ]
        ],
        ssnNumber: [''],
        driverLicense: [''],
        authorize: [{value: false, disabled: this.apiProcess}, 
          [Validators.required]
        ],
      });
      
       

      if(this.dlStoreData){
        let dob: Date;
        this.securityForm.patchValue({
          ...this.dlStoreData
        })
        dob = new Date(this.dlStoreData.dateOfBirth);
        
        this.securityForm.patchValue({
          dateOfBirth: dob
        })
      }

      this.addConditionalValidation();
    }

    addConditionalValidation() {
      const input1 = this.securityForm.get('ssnNumber');
      const input2 = this.securityForm.get('driverLicense');
    
     this.subscriptionInput1 = input1.valueChanges.subscribe(() => {
        if (!input1.value && !input2.value) {
          input2.setValidators([Validators.required, Validators.pattern(FORM_CONST.SSN_PATTERN)]);
        } else {
          input2.clearValidators();
        }
        input2.updateValueAndValidity();
      });
    
      this.subscriptionInput2 = input2.valueChanges.subscribe(() => {
        if (!input2.value && !input1.value) {
          input1.setValidators([Validators.required]);
        } else {
          input1.clearValidators();
        }
        input1.updateValueAndValidity();
      });
    }

    get ssnNumber(){
      return this.securityForm.get('ssnNumber');
    }

    get driverLicense(){
      return this.securityForm.get('driverLicense');
    }

    get authorize(){
      return this.securityForm.get('authorize');
    }

    get dateOfBirth(){
      return this.securityForm.get("dateOfBirth");
    }

    onSubmit(){
        const userID = +this.cookieService.get('x-userId');
        this.errorMessage = "";

        if(!this.authorize.value){
          this.authorize.setErrors({ customError: 'Check is required'})
        }
        
         if(this.securityForm.valid){
          this.timeOutId = window.setTimeout( () => {
            this.apiProcess = true;
          }, 1200);
          const {ssnNumber, driverLicense, authorize } = this.securityForm.value;

          const dateOfBirth = new Date(this.securityForm.value.dateOfBirth);
          const dd = dateOfBirth.getDate().toString();
          const mm = dateOfBirth.getMonth() + 1;
          const yyyy = dateOfBirth.getFullYear().toString();
          
          const dob = {
            mm : `${mm}`.length === 1 ? `0${mm}` : mm,
            dd: dd.length === 1 ? `0${dd}` : dd,
            yyyy
          }

          const payload = {
            companyId: environment.COMPANY_ID,
            userID,
            ssn: `${ssnNumber}`,
            driverLicense,
            selfConsent: authorize ? 'Yes': '',
            dateOfBirth: `${dob.mm}-${dob.dd}-${dob.yyyy}`,
          };
          // const authorize = payload.selfConsent === 'Yes' ? true : false;

          const storeData = {
            ssnNumber,
            driverLicense,
            authorize,
            dateOfBirth
          }

          if(this.dlStoreDataEndpoint && JSON.stringify(this.dlStoreData) === JSON.stringify(storeData)){
              window.clearTimeout(this.timeOutId);
              this.apiProcess = false;
              this.formSubmitted = false;
              this.router.navigate(['registration/address']);
              return;
          }

          this.localStorage.store('dlStoreData', storeData);
          this.localStorage.store('dlStoreDataEndpoint', false);

          this.subscription = this.securityServices.put(payload).subscribe(
            (response) => {
              window.clearTimeout(this.timeOutId);
              this.commonService.expiredTokenError = "";
              
              if(response.status === 200 && response.body.Status){
                this.localStorage.store('ssn', ssnNumber);
                this.localStorage.store('dl', driverLicense);
                this.apiProcess = false;
                this.formSubmitted = true;
                this.localStorage.store('dlStoreDataEndpoint', true);
                const resultData = response.body.data;
                if(resultData.enrollmentNumber){
                  this.commonService.updateEnrollment(resultData.enrollmentNumber);
                }
                if(this.prefilledInfo){
                  this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
                  this.localStorage.store('prefilledInfo', this.prefilledInfo);
                }
                const nextRoute = this.commonService.setRouter(resultData.currentStep);
                if(this.subscriptionInput1){
                  this.subscriptionInput1.unsubscribe();
                }
                if(this.subscriptionInput2){
                  this.subscriptionInput2.unsubscribe();
                }
                 
                this.router.navigate([nextRoute]);
              }
              else {
                const {body} = response;
                if('error' in body && typeof body["error"] === 'object'){
                  const keys = Object.keys(body.error);

                  keys.map( (fieldKey:string) => {
                    if(fieldKey === 'driverLicense'){
                      this.driverLicense.setErrors({customError: body["error"].driverLicense});
                    }
                    else if(fieldKey === 'dateOfBirth'){
                      this.dateOfBirth.setErrors({customError: body["error"].dateOfBirth});
                    }
                    else if(fieldKey === 'ssn'){
                      this.ssnNumber.setErrors({customError: body["error"].ssn});
                    }
                    else if(fieldKey === 'selfConsent'){
                      this.authorize.setErrors({customError: body["error"].selfConsent});
                    }
                  })
                }

                this.errorMessage = response.body.message;
                this.apiProcess = false;
                this.formSubmitted = false;
              }
            },
            (error) => {
              window.clearTimeout(this.timeOutId);
              this.commonService.expiredTokenError = "";
              this.apiProcess = false;
              this.formSubmitted = false;
              if(!error.error && !error.error.message ){
                error.error.message = "Unknown error";
              }
              this.errorMessage = error.error.message;
            }
          );
        }
        else {
          this.securityForm.markAllAsTouched();
        }
      }
     
    slidePrevious(){
      const stepData = this.stepsInfo.find( item => item.Step === 3)
      const nextRoute = this.commonService.setRouter(stepData.PrevStep);
      this.router.navigate([nextRoute]);
      // this.router.navigate(['registration/personal']);
    }

    ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
      if(this.subscriptionRefresh){
        this.subscriptionRefresh.unsubscribe();
      }

      if(this.subscriptionSecurity){
        this.subscriptionSecurity.unsubscribe();
      }
    }
}
