import { Injectable } from "@angular/core"; 
import { HttpClient,  HttpResponse } from "@angular/common/http";
import { LocalStorage } from 'ngx-webstorage';
import { ISecurityReq } from "../model/security_info.model";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SecurityFormService {
  protected baseApi: string;
  protected token: string; 
 
  @LocalStorage()
  protected uuid;
   

  constructor(  private http:HttpClient ) {
      this.baseApi = environment.BASE_API; 
  }

  put(req: ISecurityReq):Observable<HttpResponse<any>> { 
     return this.http.put(`${this.baseApi}/${environment.AUTH.SECURITY}/${this.uuid}`, req, { observe: 'response'});
    
  }
}
