import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from 'ngx-webstorage'; 
import { CommonService } from 'src/app/shared/services/common.service';
import { ESignService } from 'src/app/shared/services/esign.services';
import { ProgramServices } from 'src/app/shared/services/program.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss']
})
export class EsignComponent {
  public eSignForm;
  public isInvalidForm:boolean = false;
  public errorMessage: string = ""; 
  public apiProcess: boolean = false;
  private timeOutId: number = -1;

  public personFullName:string = "";
  public agreeCTA: boolean = false;
  public dateNow:Date = new Date();
  public errorMessageInArray: string[] = [];
  
  @ViewChild('refDivElement') innerScrollElementWrapper: ElementRef;
  @ViewChild('errorEsignModal') modelAddTemplate;
  
  @LocalStorage()
  personalInfoData

  @LocalStorage()
  personalInfo
  
  constructor(
    private router: Router,
    public programService: ProgramServices,
    private esignService: ESignService,
    private cookieService: CookieService,
    public commonService: CommonService
    
  ){  }
  
  ngOnInit(){
    let firstName, lastName;

    if(!this.personalInfoData){
      firstName =  this.personalInfo.personal.firstName;
      lastName =  this.personalInfo.personal.lastName;
    }
    else {
      firstName =  this.personalInfoData.personal.firstName;
      lastName =  this.personalInfoData.personal.lastName;
    }
    
    this.personFullName = `${firstName} ${lastName}`; 

    this.eSignForm = {
      checkbox1: {
        value: false,
        isInValid: false
      },
      checkbox2: {
        value: false,
        isInValid: false
      },
      checkbox3: {
        value: false,
        isInValid: false
      },
      checkbox4: {
        value: false,
        isInValid: false
      }, 
      
      checkbox4a: {
        value: false,
        isInValid: false
      }, 

      checkbox4b: {
        value: false,
        isInValid: false
      }, 
      checkbox4c: {
        value: false,
        isInValid: false
      }, 
      checkbox4d: {
        value: false,
        isInValid: false
      }, 
      checkbox4e: {
        value: false,
        isInValid: false
      }, 
      checkbox4f: {
        value: false,
        isInValid: false
      }, 
      checkbox4g: {
        value: false,
        isInValid: false
      }, 
      checkbox4h: {
        value: false,
        isInValid: false
      }, 
      checkbox4i: {
        value: false,
        isInValid: false
      }, 
      checkbox4j: {
        value: false,
        isInValid: false
      },
      checkbox5: {
        value: false,
        isInValid: false
      },
      checkbox6: {
        value: false,
        isInValid: false
      },
      checkbox7: {
        value: false,
        isInValid: false
      }
     }
  }

  helplineNumber =  environment.HELPLINE_NUMBER;

  checkValidation(event){
    this.errorMessage = "";
    const {name, value} = event.currentTarget;
    
    if(name === 'checkbox4'){
      this.eSignForm[name].isInValid = false;
      for(const [key, value ] of Object.entries(this.eSignForm)){
          if( /checkbox4[a-z]/.test(key) ){
          this.eSignForm[key].value = true
          this.eSignForm[key].isInValid = false
        }
      }
      return;
    } 

    if(!value){
      this.eSignForm[name].isInValid = true;
      }
      else {
        this.eSignForm[name].isInValid = false;
      }
  }


  checkAllCheckboxes(){
    this.errorMessage = '';
    for(const [checkKey, checkValue ] of Object.entries(this.eSignForm) ){
      this.eSignForm[checkKey].value = true;  
      this.eSignForm[checkKey].isInValid = false;  
    }
  }

  public clickNext(){ 
    this.errorMessage = '';
    this.errorMessageInArray = [];
    const userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID: userID,
      customerEsign: true
    }
    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);
    this.esignService.post(payload).subscribe(
      (response: HttpResponse<any>) => {
        window.clearTimeout(this.timeOutId);
        this.apiProcess = false;
        this.commonService.expiredTokenError = "";
        if(response.status === 200 && response.body.Status){
          if(response.body.data.enrollMsg.toLowerCase() === 'complete'){
            this.router.navigate(['registration/complete'])
          }
          else {
            this.router.navigate(['registration/review'])
          }
        }
        else {
          this.commonService.open(this.modelAddTemplate, 'md');

          this.errorMessage = response.body.message;
        }
      },
      (err ) => {
        window.clearTimeout(this.timeOutId);
            this.apiProcess = false;
            this.commonService.expiredTokenError = "";
            if(!err.error || !err.error.message){
              err.error.message = "Unknown error"
            }

            if(err.error.errorMsg){
              for( const [key, value] of Object.entries(err.error.errorMsg)){
                if(key === 'status'){
                  continue;
                }
                else {
                  const dynamicError = `${key} :: ${value}`;
                  this.errorMessageInArray.push(dynamicError);
                  // this.paymentService.pymentData.postResErrorCollection.push(dynamicError)
                }
  
              }
            }
            this.errorMessage = err.error.message;
            this.commonService.open(this.modelAddTemplate, 'md');
      }
    )
  }

  onScroll(event: any) {
    const cH = event.target.clientHeight + 400;
    const wrapH = this.innerScrollElementWrapper.nativeElement.clientHeight;
    const remainH = wrapH - cH;
    
    if( remainH < event.target.scrollTop ){
      this.checkAllCheckboxes();
      this.agreeCTA = true
    }
  }

  

}
