import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { PaymentServices } from 'src/app/shared/services/payment.services';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./../review.scss']
})

export class PreviewComponent {

  @LocalStorage()
  public enrollmentIdStorage;
  
  @LocalStorage()
  public prefilledInfo;
  

  public enrollMsg: string = 'complete' 

  constructor(
    public paymentService: PaymentServices,
    public commonService: CommonService,
    private router: Router
  ){
    this.commonService.setStepInfo('registration/complete');
  }

  
  completed(){
    this.router.navigate([''])
  }
   
}
