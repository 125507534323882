<ng-container *ngIf="!toggleForApprovalCard; else toggleApprovalCard">
    <div *ngIf="programService.loadProgram else loaderContent">
       <div class="login-main program-form p-relative custom-p-20">
           <div class="form-group mt-3 p-absolute btn-top-action text-end">
              <button class="btn btn-light btn-lg mx-3" [disabled]="commonService.lockPreviousStep" (click)="stepPrevious()">Back</button>
              <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="saveProgramDataAndValidate()">Next</button>
          </div>
          <h4 class="font-primary p-t-20">Qualify for Affordable Connectivity Program</h4>
          <p class="f-16">Please select a program below to show you, your dependent or someone in your household qualifies for the Affordable Connectivity Program. You can qualify for the Affordable Connectivity Program by showing that you already participate in one of the government assistance programs below. If you do not currently participate in one of the programs listed, you may be able to qualify based on income. You may be required to show proof of your participation in the program you choose or show proof of income.</p>
          <div class="list-group">
             <ng-container *ngFor="let program of programService.programs; index as i">
                   <ng-container *ngIf="programService.hasModal(program.shortName) then hasContentModal else hasNoContentModal"></ng-container>
                   <ng-template #hasContentModal>
                      <ng-container *ngIf="program.shortName === 'NSL'">
                         <app-list-school-lunch [index]="i" [program]="program"></app-list-school-lunch>
                      </ng-container>
                      <ng-container *ngIf="program.shortName === 'FPH'">
                         <app-list-housing [index]="i" [program]="program"></app-list-housing>
                      </ng-container>
                   </ng-template>
                   <ng-template #hasNoContentModal>
                        <div
                           [ngClass]="{'active': programService.checkSelected(program.shortName)}"
                           class="list-group-item list-group-item-action js-check-card d-flex justify-content-between align-items-center"
                          >
                           <h5 class="m-r-10">{{program.name}}</h5>
                           <button 
                              class="btn btn-pill" 
                              [ngClass]="{
                                 'btn-secondary': programService.checkSelected(program.shortName),
                                 'btn-primary': !programService.checkSelected(program.shortName)
                                 }"
                              (click)="programService.renderAdditionalForm(program)">
                                 {{programService.checkSelected(program.shortName) ? 'Remove' : 'Choose'}}
                              </button>
                        </div>
                  </ng-template>
          </ng-container>  
       </div>
       <ng-container *ngFor="let program of programService.programs; index as i">
          <ng-container *ngIf="program.shortName === 'IB'">
             <h4 class="font-primary m-t-30 m-b-20">Qualify based on Income</h4>
             <div class="list-group">
                <app-list-income [index]="i" [program]="program"></app-list-income>
             </div>
          </ng-container>
       </ng-container>
       <!-- <ng-container *ngIf="!commonService.expiredTokenError && errorMessage">
          <div class="text-danger f-16" *ngFor="let err of errorMessage">{{err.label}}</div>
       </ng-container> -->
       <div class="text-danger f-16" *ngIf="commonService.expiredTokenError">{{ commonService.expiredTokenError }}</div>
       <div class="form-group m-t-20 text-end">
          <button class="btn btn-light btn-lg mx-3" [disabled]="commonService.lockPreviousStep" (click)="stepPrevious()">Back</button>
          <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="saveProgramDataAndValidate()">Next</button>
       </div>
    </div>

    <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
       <div  class="loader-box">
          <div class="loader-9"></div>
       </div>
    </div>
    </div>
    <ng-template #loaderContent>
       <div class="p-2">
          <div class="login-main program-form">
             <div class="loader-box">
                <div class="loader-9"></div>
             </div>
          </div>
       </div>
    </ng-template>
  </ng-container>
  
  <ng-template #toggleApprovalCard>
     <!-- <div class="login-main program-form">
       <div class="card-body text-center">
         <div class="success-icon text-center mb-3">
           <app-feather-icons [icon]="'check-circle'"></app-feather-icons>  
         </div>
         <h1 class="f-w-100 txt-success">Congratulation! <br/>
           <small>you're pre approved</small>
         </h1>
         <div class="p-2 m-b-10">
          
         <a *ngIf="!commonService.showCallbackUrlButton" class="text-center btn btn-primary btn-lg d-block" [href]="resolutionUrl">
           Click here to finish your application with National Verifier
        </a></div>
        <div class="text-center btn btn-primary d-block" *ngIf="commonService.showCallbackUrlButton">
         <button class="btn btn-primary btn-lg" (click)="continueAction()">Continue...</button>
       </div>
         <div class="text-center m-b-10">
           <a class="btn btn-light" [href]="'tel:'+helpline">
             <app-feather-icons [icon]="'phone-call'"></app-feather-icons> 
             <span class="line-height-24">Give us a call {{helpline}}</span>
           </a>
         </div>
         <div class="text-center m-b-10">
           <button class="btn btn-light btn-lg">
             Chat with an agent 
           </button>
         </div>
         <div class="text-center m-t-20  m-b-20">
           It might take upto 48 hours until the National Verifier validates your eligibility.
         </div>
         <div class="b-dark text-center m-t-10 p-2">
           <app-feather-icons class="beware-icon" [icon]="'alert-triangle'"></app-feather-icons> Beware <br/>
           Make sure you don't have an open application or an active account with other companies. If you do, your tablet order will be cancelled.
         </div>
       </div>
   </div> -->
   <app-complete [href]="resolutionUrl"></app-complete>
   <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
     <div class="loader-box">
         <div class="loader-9"></div>
     </div>
   </div>
  </ng-template>
  
  <ng-template #errorModalMessage let-modal>
     <div class="modal-body max-360">
        <div class="close-modal p-absolute" (click)="modal.close()">
           <i class="icon-close"></i>
        </div>
        <div class="text-center f-18 f-w-500">
         IMPORTANT
        </div>
        <div class="theme-form p-20">
           <div class="text-center text-danger img-wrapper">
               <app-feather-icons [icon]="'alert-octagon'"></app-feather-icons>       
           </div>
        </div>
        <ng-container *ngIf="!commonService.expiredTokenError && errorMessage">
            <div class="text-danger f-16" *ngFor="let err of errorMessage">{{err.label}}</div>
         </ng-container>
        <ng-container *ngIf="!commonService.expiredTokenError && errorMessageEndpoint">
           <div class="f-16 p-b-20 text-center text-strong">
              {{ errorMessageEndpoint }}
              <!-- <a href="javascript:void(0)" class="link" (click)="errorMessageSeeMore()">See More</a> -->
           </div>
        </ng-container>
        <ng-container *ngIf="errValues && errorMessageEndpoint">
           <div class="text-danger f-16" *ngFor="let pErrItem of errorObject | keyvalue;let i=index">{{
               pErrItem.key + ':: '+ pErrItem.value
            }}</div>
       </ng-container>
     </div>
  </ng-template>
  