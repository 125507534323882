  <div 
  class="card cursor-pointer"
  [ngClass]="{
    'b-success': planService.dataPlanDevice.selectedCardId === plan.id
    }"
  (click)="selectPlan(plan.id)"
  >
   <!-- <img class="card-img-top border-top-left-radius" src="./../../../../../assets/images/banner/2.jpg" alt="Card image cap"> -->
    <img *ngIf="plan.imagePath" class="card-img-top" class="card-img-top border-top-left-radius" src="{{plan.imagePath}}"  alt="card image" />
    <div 
    class="card-body">
      <div class="p-absolute price-badge">
        <span class="badge badge-warning tag-pills-sm-mb">
          ${{plan.price}}
        </span>
      </div>
      <h5 class="card-title">{{plan.name}}</h5>
      <h6 class="card-title mt-3">{{plan.displayName}}</h6>
      <p class="card-text">{{plan.description}}</p>
      <ul *ngIf="plan.banners.length > 0" class="list-group list-group-flush">
      <ng-container *ngFor="let serviceItem of plan.banners">
          <li class="list-group-item">
            <i class="fa fa-check-square-o font-success"></i>  
            {{serviceItem.displayName}}
          </li>
      </ng-container>
    </ul>
    <div class="text-center mt-3">
      <button class="btn btn-primary" [disabled]="planService.dataPlanDevice.selectedCardId !== plan.id"
      (click)="planService.saveSelectedPlan()"
      >Continue with ${{plan.price}}</button>
    </div>
    </div>
  </div>