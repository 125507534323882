import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service"; 
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class AdminGuard  {
  cookieValue: string;

  constructor(public router: Router, private cookieService: CookieService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.cookieValue = this.cookieService.get('x-token');
    if (!this.cookieValue) {
       this.router.navigate(["/network-strength"]);
       return false;
    } 
    return true;
  }
}
