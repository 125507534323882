<div class="login-main register-form p-relative p-4">
  <div class="form-group mt-3 p-absolute btn-top-action text-end">
    <button type="button" class="btn btn-light btn-lg mx-3" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="slidePrevious()">Back</button>
    <button type="submit" [disabled]="!commonService.expiredTokenError && apiProcess" class="btn btn-primary btn-lg" (click)="onSubmit()">Next</button>
  </div>
  <h4 class="font-primary">Security Information</h4>
  <form class="theme-form" (ngSubmit)="onSubmit()" [formGroup]="securityForm">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group mb-3">
            <label class="col-form-label f-16" for="personalFormDateOfBirth">Date of Birth*</label>
            <input 
                type="text"
                formControlName="dateOfBirth"
                name="dateOfBirth"
                id="personalFormDateOfBirth"
                class="form-control"
                placeholder="mm/dd/yyyy"
                aria-label="Date of Birth"
                [ngClass]="{'is-invalid':dateOfBirth.touched && dateOfBirth.invalid}"
                aria-describedby="basic-addon1"
                bsDatepicker
                [bsConfig]="datePickerConfig"
                >
                <div class="invalid-feedback">
                     Incorrect ages. User ages should be in between 18-100 years.
                </div>
                <div class="text-danger f-12" *ngIf="dateOfBirth.errors">{{ dateOfBirth.errors["customError"]}}</div>
            </div>
        </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group mb-3">
          <label class="col-form-label f-16">SSN (Last 4 digit)</label>
          <input 
              type="text"
              formControlName="ssnNumber"
              class="form-control"
              placeholder="****"
              aria-label="SSN Name"
              maxlength="4"
              [ngClass]="{'is-invalid':ssnNumber.touched && ssnNumber.invalid}"
              aria-describedby="basic-addon1">
              <div class="invalid-feedback">
                  Required field. Must have a number with 4 digit.
              </div>
              <div class="text-danger f-12" *ngIf="ssnNumber.errors">{{ ssnNumber.errors["customError"]}}</div>
          </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="form-group mb-3">
        <label class="col-form-label f-16">DL / ID</label>
        <input 
            type="text"
            formControlName="driverLicense"
            class="form-control"
            placeholder="XXXXXX"
            [ngClass]="{'is-invalid':driverLicense.touched && driverLicense.invalid}"
            aria-label="Driver License"
            maxlength="100"
            aria-describedby="basic-addon1">
          <div class="invalid-feedback">
            Required field.
        </div>
        <div class="text-danger f-12" *ngIf="driverLicense.errors">{{ driverLicense.errors["customError"]}}</div>
    </div>
    </div>
</div>
<div class="col-xs-12 col-sm-3">
  <div class="form-group mt-1">
      <div class="form-check checkbox checkbox-primary">
        <input 
            formControlName="authorize"
            aria-label="You are authorizing Unity License Wireless Inc."
            type="checkbox"
            class="form-check-input"
            [ngClass]="{'is-invalid':authorize.touched && authorize.invalid}"
            id="authorize"
          />
          <label 
            class="checkbox-primary-1 f-16"
            for="authorize"
            [ngClass]="{'form-check-label': authorize.value}"
            >
            Check to authorize
          </label>
      </div>
      <div class="text-danger f-12" *ngIf="authorize.errors">{{ authorize.errors["customError"]}}</div>
</div>

</div>
<div class="text-danger f-12" *ngIf="!commonService.expiredTokenError && errorMessage">{{errorMessage}}</div>
<div class="text-danger f-12" *ngIf="commonService.expiredTokenError">
  {{ commonService.expiredTokenError }}
</div>
<div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
  <div  class="loader-box">
      <div class="loader-9"></div>
  </div>  
</div>
<div class="form-group mt-3 text-end">
  <button type="button" class="btn btn-light btn-lg mx-3" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="slidePrevious()">Back</button>
  <button type="submit" [disabled]="!commonService.expiredTokenError && apiProcess" class="btn btn-primary btn-lg">Next</button>
</div>
  </form>
</div>    