import {Injectable} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { LocalStorage } from 'ngx-webstorage';
import { IQuestionsReq } from "../model/questions.modal";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})

export class QuestionServices {
  protected baseApi: string;
  protected token: string; 

    
    @LocalStorage()
    protected uuid;

    @LocalStorage()
    protected xExpire;

    constructor(
        private http:HttpClient,
        private cookieService: CookieService) {
        this.baseApi = environment.BASE_API;
        this.token =  this.token = this.cookieService.get('x-token');

    }

  put(req: IQuestionsReq):Observable<HttpResponse<any>> { 
    return this.http.put(`${this.baseApi}/${environment.AUTH.QUESTIONS}/${this.uuid}`, req, { observe: 'response'});
  }
}
