<div class="row m-b-20">
    <div class="col-12 col-md-6">
        <div class="congrat-message-wrapper">
            <div class="text-center image-wrapper">
                <img src="assets/images/image1/asset1.png" class="img-custom-fuild" alt="img" />
            </div>
            <h4>You&rsquo;re Almost Done, {{ personFullName }}</h4>
            <div class="text-center image-wrapper">
                <img width="80" src="assets/images/image1/asset3.png" alt="img" />
            </div>
            <h4>Good news!</h4>
            <p class="f-16 mt-2">You&rsquo;re approved by Unity Wireless!</p>
            <p class="f-16 mt-2">But your application is still pending at National Verifier. Click on &ldquo;Complete National Verifier&rdquo; button below to visit &
            update your information in National Verifier Database.</p>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="content-wrapper">
            <div class="card p-4">
                <h4>1. Apply To Unity WireLess</h4>
                <p>Congrats, You're pre-approved by Unity WireLess</p>
            </div>
            <div class="card p-4">
                <h4>2. Complete National Verifier</h4>
                <p class="f-16">The Subscriber&rsquo;s eligibility could not be determined. Please upload proof of eligibility using the Complete National Verifier below.</p>
                <p class="f-16">Click on &lsquo;Complete National Verifier&rsquo; below to update in National Verifier.</p>
                <div class="text-center image-wrapper">
                    <img src="assets/images/image1/asset6.png" class="img-custom-fuild" alt="img" />
                </div>
                <p class="text-center f-16 mt-2"><em>TRANSFERRING YOU TO THE NATIONAL VERIFIER</em></p>
                <div class="text-center">
                    <a class="btn text-center btn-custom-primary text-strong" [href]="href">Complete National Verifier</a>
                </div>
            </div>
        </div>
    </div>
</div>

