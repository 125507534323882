export const FORM_CONST = {
    PHONE_NUMBER_PATTERN:  /^[2-9]\d{9}$/,
    PHONE_NUMBER_PATTERN_US: /^\(\d{3}\)\s\d{3}\s-\s\d{4}$/,
    ZIP_CODE_PATTERN: /(^\d{5}$)/,
    DATE_PATTERN: /\d{2}\/\d{2}\/\d{4}/,
    SSN_PATTERN: /\d{4}/,
    DL_PATTERN: /^[A-Za-z0-9]*[:-]?[A-Za-z0-9]*$/,
    NAME_PATTERN: /^[a-zA-Z][a-zA-Z\s`'-]*$/
} as const;

export const FORM_CC = {
    CVV: /\d{3}/,
    CC_NUMBER: /\d{16}/,
} as const;


export const generateNewStore = (payload) => {
    const obj = Object.assign( {}, payload);
    delete obj["companyId"];
    delete obj["userID"];
    delete obj["zipCode"];
    delete obj["email"];
    delete obj["enrolmentType"];
    delete obj["source"];

    return obj;
}
