  <div 
    class="login-main register-form complete-form"
    >
    <div class="banner-image-wrapper"> 
      <div class="banner-image-wrapper-1"> 
       <div class="text-center mb-3">
          <div class="f-12 mini-title m-b-20">Your Enrollment ID is : {{enrollmentIdStorage}}</div>
          <p class="f-w-600 text-center f-16 custom-color-1">Registration Successful <img class="img-inline" width="25" src="./assets/images/image1/tick.png" /> <br/>
            Your Enrollment ID is : {{enrollmentIdStorage}}</p>
            <div class="img-wrapper congratulation-image">
                <img src="./assets/images/completePage/congrates.png" alt="image" />
            </div>
            <div class="max-400-wrapper">
              <p class="f-w-500 text-center f-16 p-t-20 p-b-40 l-h-1_5 custom-color-3">Your order is pending at National Verifier. It will be approved automatically once we get approval from National Verifier. Post successful validation, you will receive the confirmation message.</p>
            </div>
          <div class="d-flex f-18 align-items-center justify-content-center">
            <p class="f-w-600 text-center custom-color-dark f-18">Hey! Got a second?
            <span class="img-inline">
              <img src="./assets/images/completePage/thumbsUp.png" alt="thumbs up" />
            </span>
          </p>
          </div> 
          <div class="max-400-wrapper">
            <p class="f-14 custom-color-3 text-center">We want your feedback, Please click the link below to complete our survey.<br/>
              We really appreciate your opinion.<br/>
              <img src="./assets/images/completePage/rating.png" width="200" alt="starRating" />
            </p>
          </div>
            <div class="form-group mb-3 mt-3 text-center">
              <a class="btn btn-primary btn-lg mx-3" href="https://survey.zigpoll.com/2th9Mdxyor4DzxCZ1/2th9MehoL3csdDs3a">Get Started</a>
              <!-- <button class="btn btn-secondary btn-lg">Learn More</button> -->
          </div>
        </div>
        </div>
    </div>
 </div>
