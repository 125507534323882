interface IItem {
    value: any;
    isValid?: boolean;
}

export function clearValidation(fieldList:Record<string, IItem>){
    for(const [itemKey, itemValue] of Object.entries(fieldList)){
        if('isValid' in itemValue){
            const {isValid} = itemValue;
            if(isValid){
                fieldList[itemKey].isValid = false;
            }
        }
    }
}

export function applyValidation(fieldList:Record<string, IItem>){
    for(const [itemKey, itemValue] of Object.entries(fieldList)){

        if(itemKey === "dateOfBirth"){
            const res = typeof itemValue?.value === 'object' ? false : true;
            fieldList[itemKey].isValid = res;
        }
        if(itemKey === "isBqpConsent"){
            const res = itemValue.value ? false : true;
            fieldList[itemKey].isValid = res;
        }

        if('isValid' in itemValue){
            const {value, isValid} = itemValue;
            const check = !! value && !isValid;
            if(!check){
                fieldList[itemKey].isValid = true;
            }
        }
    }
}

export  function throwMessages(fieldList:Record<string, IItem>){
    let invalidForm = false;
    for(const [, itemValue] of Object.entries(fieldList)){
        if('isValid' in itemValue){
           if(itemValue.isValid === true){
            invalidForm = true;
            break;
           }
        }
    }
    return invalidForm;
}