import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http"; 
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject } from 'rxjs';
import { LocalStorage } from "ngx-webstorage"; 
import { IESign } from "../model/common.model";


@Injectable({
  providedIn: 'root'
})

export class ESignService {

    protected baseApi: string; 

    private myDataSubject = new ReplaySubject<HttpResponse<any>| null>(null);
    myData$ = this.myDataSubject.asObservable();

    @LocalStorage()
    protected xExpire;

    @LocalStorage()
    protected uuid;

  constructor(
    private http: HttpClient,
    ) { 
        this.baseApi = environment.BASE_API;
      }

    post(req: IESign):Observable<HttpResponse<any>> {
      return this.http.post(`${this.baseApi}/${environment.AUTH.E_SIGN}/${this.uuid}`, req, {observe: 'response'});        
    }
  }
