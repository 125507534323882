import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http"; 
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { IGetPaymentTrans, IPaymentReq, IZeroPaymentReq } from "../model/payment.model";
import { LocalStorage } from "ngx-webstorage"; 

@Injectable({
  providedIn: 'root'
})

export class PaymentServices {

    protected baseApi: string;
    protected token: string; 
    public paymentData = {
        getResData: '',
        getCallerror: '',
        postResData: '',
        postResError: '',
        postResErrorCollection: []

    }; 

    @LocalStorage()
    protected uuid; 


  constructor(  private http: HttpClient  ) { 
        this.baseApi = environment.BASE_API; 
    }

     post(req: IPaymentReq): Observable<any> { 
     
        return this.http.post(`${this.baseApi}/${environment.AUTH.PAYMENT}/${this.uuid}`, req, { observe: 'response'});
    }

    makeZeroPayment(req: IZeroPaymentReq): Observable<any> {
        return this.http.post(`${this.baseApi}/${environment.AUTH.ZERO_PAYMENT}/${this.uuid}`, req, {  observe: 'response'});
    } 
    
    getPaymentReq(req:IGetPaymentTrans): Observable<any> { 
        const {userID, companyId } = req;
        const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);

        return this.http.get(`${this.baseApi}/${environment.AUTH.PAYMENT_TRANS}/${this.uuid}`, { params, observe: 'response' });
    }
}
